/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Button,
} from 'reactstrap';

const CreateTopicForm = ({ handleSubmit }) => (
  <Col md={12} lg={12}>
    <Card className="card--not-full-height">
      <CardBody className="dashboard__booking-card">
        <form className="form" onSubmit={handleSubmit}>
          <span className="form__form-group-label">Urutan Topik</span>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <Field
                name="order"
                component="input"
                type="number"
                placeholder="Urutan topik"
                required
              />
            </div>
          </div>
          <span className="form__form-group-label">Judul Topik</span>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <Field
                name="title"
                component="input"
                type="text"
                placeholder="Judul Topik"
                required
              />
            </div>
          </div>
          <ButtonToolbar className="form__button-toolbar">
            <Button
              color="primary"
              type="submit"
            // disabled={!selectedStudent}
            >
              Simpan
            </Button>
          </ButtonToolbar>
        </form>
      </CardBody>
    </Card>
  </Col>
);

CreateTopicForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'topic_create', // a unique identifier for this form
})(CreateTopicForm);
