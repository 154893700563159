/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
// import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonToolbar,
  Card, CardBody, Col,
} from 'reactstrap';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import CourseMaterialContent from './components/materialContent';
import { URL_TOPIC } from '../../../../utils/EndPoints';
import { LOCALSTORAGE_TOKEN } from '../../../../utils/Types';

const CourseMaterial = () => {
  const history = useHistory();
  const { id } = useParams();
  const [material, setMaterial] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(`${URL_TOPIC + id}/`, options)
      .then((res) => {
        setMaterial(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <ButtonToolbar className="form__button-toolbar">
          <Button color="danger" onClick={() => { history.push(`/admin/course-topic/${material.courseId}`); }}>
            Kembali
          </Button>
        </ButtonToolbar>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Materi Topik {material != null && material.title}</h5>
            {/* <h5 className="subhead">Use default tabs with classes
            <span className="red-text"> tabs--justify tabs--bordered-bottom</span>
          </h5> */}
          </div>
          <div className="tabs tabs--justify tabs--bordered-bottom">
            <CourseMaterialContent />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default CourseMaterial;
