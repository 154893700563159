/* eslint-disable no-shadow */
/* eslint-disable no-console */
import React from 'react';
import {
  Container,
} from 'reactstrap';
import EditUserForm from './components/editUserForm';

const EditUser = () => (
  <Container className="dashboard">
    <EditUserForm />
  </Container>
);
export default EditUser;
