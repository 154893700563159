/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Alert,
  Container, Row,
} from 'reactstrap';
import { LOCALSTORAGE_TOKEN } from '../../../../utils/Types';
import { URL_DATAASN, URL_USERS } from '../../../../utils/EndPoints';
import UserListTable from './components/userList';
import ListDataUser from './components/listDataUser';

const UserList = () => {
  const [user, setUser] = useState([]);
  const listDataTable = ListDataUser(user);
  const [isLoading, setLoading] = useState(false);
  const [errorGet, setErrorGet] = useState('');

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios.get(`${URL_USERS}`, options)
      .then((res) => {
        setUser(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setErrorGet(err.response.status);
        setLoading(false);
      });
  }, []);

  return (
    <Container className="dashboard">
      {/* <ButtonToolbar className="form__button-toolbar">
        <Button color="primary" onClick={history.goBack}>
        Back
        </Button>
      </ButtonToolbar> */}
      <Row>
        {errorGet !== '' && (
          <div className="login-form" style={{ marginBottom: '20px' }}>
            <Alert color="danger">Gagal mendapatkan data. [{errorGet}]</Alert>
          </div>
        )}
        <UserListTable
          listDataTable={listDataTable}
          isLoading={isLoading}
        />
      </Row>
    </Container>
  );
};
export default UserList;
