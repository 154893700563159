/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/react-in-jsx-scope */
import
React,
{ useMemo } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

const testimonialDetail = (history, info) => {
  history.push({
    pathname: `/admin/testimonial-detail/${info.id}`,
    state: { data: info },
  });
};

const ListDataTestimonial = (dataAPI) => {
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: 'No',
        accessor: 'number',
        width: 20,
        disableSortBy: true,
      },
      {
        Header: 'Nama',
        accessor: 'name',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Posisi',
        accessor: 'position',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
    ], [],
  );

  const data = [];
  const rows = () => {
    if (!dataAPI) {
      data.push({
        number: '-',
      });
    } else {
      for (let i = 0; i < dataAPI.length; i += 1) {
        data.push({
          id: dataAPI[i].id,
          number: `${i + 1}`,
          name: dataAPI[i].name,
          position: dataAPI[i].position,
          status: [
            // eslint-disable-next-line no-loop-func
            // eslint-disable-next-line max-len
            (<Button key={dataAPI[i].id} color="outline-success" onClick={() => testimonialDetail(history, dataAPI[i])}>Detail</Button>),
          ],
        });
      }
    }
  };

  rows();
  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default ListDataTestimonial;
