/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState, useRef, useEffect,
} from 'react';
import { Field, reduxForm } from 'redux-form';
import { Editor } from '@tinymce/tinymce-react';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Progress, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import {
  LOCALSTORAGE_TOKEN,
} from '../../../../../utils/Types';
import {
  URL_VIDEO_CONTENT,
} from '../../../../../utils/EndPoints';

const VideoContentEditForm = (stateOri) => {
  const history = useHistory();
  const params = useParams();
  const { location } = history;
  const { state } = location;
  const { initialize } = stateOri;
  const { data } = state;
  const [progress, setProgress] = useState(null);
  const editorRef = useRef(null);
  const [content, setContents] = useState(data.description);
  const [isEditable, setEditable] = useState(false);
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  useEffect(() => {
    initialize(data);
  }, []);

  const handleEditorChange = (contents) => {
    setContents(contents);
  };

  const onSubmit = (e) => {
    // setLoading(true);
    e.preventDefault();
    const data = new FormData();
    data.append('title', e.target.title.value);
    data.append('link_video', e.target.link_video.value);
    data.append('description', content);

    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: (data) => {
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    };
    axios
      .patch(`${URL_VIDEO_CONTENT + params.id}/`, data, options)
      .then(() => {
        history.push('/admin/video-content');
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  };

  return (
    <Col md={12} lg={12}>
      <ButtonToolbar className="form__button-toolbar">
        <Button color="danger" onClick={history.goBack}>
          Kembali
        </Button>
      </ButtonToolbar>
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <div className="card__title">
            <h5 className="bold-text">Buat Widyaiswara Baru</h5>
          </div>
          <form className="form" onSubmit={onSubmit}>
            <span className="form__form-group-label">Judul</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="title"
                  component="input"
                  type="text"
                  placeholder="Judul"
                  required
                  disabled={!isEditable}
                />
              </div>
            </div>

            <span className="form__form-group-label">Link Video</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="link_video"
                  component="input"
                  type="url"
                  placeholder="Link Video"
                  required
                  disabled={!isEditable}
                />
              </div>
            </div>

            <span className="form__form-group-label">Deskripsi</span>
            <div className="form__form-group">
              <div className="form__form-group-field w-100-role-application">
                <input
                  id="my-file"
                  type="file"
                  name="my-file"
                  style={{ display: 'none' }}
                />
                <Editor
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  apiKey="koceoiwytlyiwg8ifoagfwdyi3wvjg5fk88o3gakacslqc8y"
                  // apiKey="s1v3xcapuudocok0xo9u4502w4vhr95x55u12id0fc7nbfp2"
                  initialValue={data.description}
                  disabled={!isEditable}
                  init={{
                    skin: 'snow',
                    icons: 'thin',
                    // placeholder:`${t('dashboard_teacher.create_course.description_message')}`,

                    height: 500,
                    menubar: true,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen textcolor ',
                      'insertdatetime media table paste code help wordcount',
                      'image code',
                    ],
                    image_title: true,
                    automatic_uploads: true,
                    file_picker_types: 'image',
                    textcolor_rows: '4',
                    file_picker_callback(callback, value, meta) {
                      if (meta.filetype === 'image') {
                        const input = document.getElementById('my-file');
                        input.click();
                        input.onchange = function () {
                          const file = input.files[0];
                          const reader = new FileReader();
                          reader.onload = function (e) {
                            callback(e.target.result, {
                              alt: file.name,
                            });
                          };
                          reader.readAsDataURL(file);
                        };
                      }
                    },

                    content_style:
                      "@import url('http://fonts.gstatic.com/s/nunito/v7/1TiHc9yag0wq3lDO9cw0vpBw1xU1rKptJj_0jans920.woff2');",
                    font_formats: 'Nunito',

                    toolbar:
                      'undo redo | styleselect | fontselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ',
                  }}
                  onEditorChange={handleEditorChange}
                  outputFormat="html"
                />
              </div>
            </div>

            {progress !== null && (
              <Card>
                <Col md={12} lg={12}>
                  <Progress animated value={progress}>{progress}%</Progress>
                </Col>
              </Card>
            )}

            <ButtonToolbar className="form__button-toolbar">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setEditable(!isEditable)}
                color={isEditable ? 'danger' : 'primary'}
              >
                {' '}
                {!isEditable ? 'Ubah' : 'Batal'}
              </button>
              {isEditable && (
              <button
                type="submit"
                className="btn btn-primary"
              >
                {' '}
                Simpan Perubahan
              </button>
              )}
              {isEditable === false && (
              <button
                type="button"
                size="sm"
                onClick={() => toggle()}
                className="btn btn-danger"
              >
                Hapus
              </button>
              )}
              <Modal
                toggle={toggle}
                isOpen={modal}
                backdrop={false}
              >
                <ModalHeader toggle={toggle}>
                  Hapus Widyaiswara
                </ModalHeader>
                <ModalBody>
                  Apakah kamu yakin ingin menghapus Widyaiswara ini?
                </ModalBody>
                <ModalFooter>
                  <Button
                    onClick={() => {
                      const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                      const options = {
                        headers: {
                          'Content-Type':
                                'application/json',
                          Authorization: `Token ${token}`,
                        },
                      };
                      axios
                        .delete(
                          `${URL_VIDEO_CONTENT}${data.id}`,
                          options,
                        )
                        .then(() => {
                          history.push('/admin/video-content/');
                        })
                        .catch(() => {
                          console.log('error');
                        });
                    }}
                  >
                    Hapus
                  </Button>
                </ModalFooter>
              </Modal>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

// CreateCourseForm.propTypes = {
//   handleSubmit: PropTypes.func.isRequired,
// };

export default reduxForm({
  form: 'CreateELibrary_form', // a unique identifier for this form
})(VideoContentEditForm);
