/* eslint-disable no-console */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Spinner, Button, ButtonToolbar,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import ReactTableBase from '../../../../../../shared/components/table/ReactTableBase';

const SubmissionListTable = ({ listDataTable, isLoading }) => {
  const tableConfig = {
    isEditable: false,
    isSortable: true,
    isResizable: false,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
  };

  const history = useHistory();

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="dashboard__booking-card">
          <ButtonToolbar className="form__button-toolbar">
            <Button color="danger" onClick={history.goBack}>
              Kembali
            </Button>
          </ButtonToolbar>
          <div className="card__title">
            <h5 className="bold-text">List Submission Siswa</h5>
          </div>
          {isLoading ? <Spinner /> : (
            <ReactTableBase
              columns={listDataTable.tableHeaderData}
              data={listDataTable.tableRowsData}
              tableConfig={tableConfig}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

SubmissionListTable.propTypes = {
  listDataTable: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default SubmissionListTable;
