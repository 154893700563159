export const BASEURL = 'https://api.mooc-bpsdm.kalteng.go.id';
// export const BASEURL = 'https://ec2-3-0-18-226.ap-southeast-1.compute.amazonaws.com';
export const AUTH_URL = `${BASEURL}/auth-asn/`;

// user
export const URL_USERS = `${BASEURL}/api-asn/user/`;

// reset password
export const URL_RESET_PASSWORD = `${BASEURL}/api-asn/password_reset/`;
export const URL_CHANGE_PASSWORD = `${BASEURL}/api-asn/password_reset/confirm/`;

// user role
export const URL_USERBYROLE = `${BASEURL}/api-asn/userbyrole/`;
export const URL_DATAASN = `${BASEURL}/api-asn/dataasn/`;

// auth user
export const URL_TEACHER = `${BASEURL}/api-asn/instructor/`;
export const URL_TEACHER_REGIS = `${BASEURL}/auth-asn/users/`;

// category & subcategory
export const URL_CATEGORYCOURSE = `${BASEURL}/api-asn/categorycourse/`;
export const URL_SUBCATEGORYCOURSE = `${BASEURL}/api-asn/sucategorycourse/`;
export const URL_SUBCATEGORYCOURSEBYCATEGORY = `${BASEURL}/api-asn/subcategorybyidcategory/`;

// course
export const URL_COURSE = `${BASEURL}/api-asn/course/`;

// topic
export const URL_TOPIC_BY_IDCOURSE = `${BASEURL}/api-asn/topicbyidcourse/`;
export const URL_TOPIC = `${BASEURL}/api-asn/topic/`;

// material
export const URL_MATERIAL_BY_IDTOPIC = `${BASEURL}/api-asn/materialbyidtopic/`;
export const URL_MATERIAL = `${BASEURL}/api-asn/material/`;

// quiz
export const URL_QUIZ_BY_IDTOPIC = `${BASEURL}/api-asn/quizbyidtopic/`;
export const URL_QUIZ = `${BASEURL}/api-asn/quiz/`;

// quiz question
export const URL_QUESTION_BY_IDQUIZ = `${BASEURL}/api-asn/questionbyidquiz/`;
export const URL_QUESTION = `${BASEURL}/api-asn/question/`;

// atendance
export const URL_ATTENDANCE_BY_IDCOURSE = `${BASEURL}/api-asn/attendancebyidcourse/`;
export const URL_ATTENDANCE = `${BASEURL}/api-asn/attendancecourse/`;

// Live Session
export const URL_LIVE_SESSION = `${BASEURL}/api-asn/livesession/`;
export const URL_LIVE_SESSION_BY_IDTOPIC = `${BASEURL}/api-asn/livesessionbyidtopic/`;

// learning stats
export const URL_USERSTATS = `${BASEURL}/api-asn/statslerninguser/`;

// Live Session attendance
export const URL_EVENT_ATTENDANCE = `${BASEURL}/api-asn/attendanceevent/`;

// e-library
export const URL_LIBRARY = `${BASEURL}/api-asn/publishelibrary/`;

// testimonial
export const URL_TESTIMONIAL = `${BASEURL}/api-asn/testimonial/`;

// video content
export const URL_VIDEO_CONTENT = `${BASEURL}/api-asn/videocontent/`;

// student list
export const URL_STUDENT_LIST = `${BASEURL}/api-asn/enrolledbyidcourse/`;
export const URL_STUDENT_LIST_CUSTOM = `${BASEURL}/api-asn/customenrolledbyidcourse/`;

// instructor
export const URL_INSTRUCTOR = `${BASEURL}/api-asn/instructor/`;
export const URL_INSTRUCTOR_BY_IDCOURSE = `${BASEURL}/api-asn/instructorbyidcourse/`;

// score quiz
export const URL_SCORE = `${BASEURL}/api-asn/score/`;
export const URL_SCORE_BY_IDQUIZ = `${BASEURL}/api-asn/scorebyidquiz/`;

// assignment
export const URL_ASSIGNMENT = `${BASEURL}/api-asn/assignment/`;
export const URL_ASSIGNMENTBYTOPIC = `${BASEURL}/api-asn/assignmentbyidtopic/`;

// submission assignment
export const URL_SUBMISSION_BY_IDASSIGNMENT = `${BASEURL}/api-asn/submissionbyidassignment/`;
export const URL_SUBMISSION = `${BASEURL}/api-asn/submissionassignment/`;

// certificate course
export const URL_CERTIFICATE = `${BASEURL}/api-asn/certificatecourse/`;
export const URL_CERTIFICATEPERMOUNTH = `${BASEURL}/api-asn/certificatestats/`;
