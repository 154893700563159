/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonToolbar,
  Card, CardBody, Col,
} from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import EditAssigntmentForm from './components/editAssigntmentForm';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/Types';
import { URL_ASSIGNMENT } from '../../../../../utils/EndPoints';

const EditAssignment = () => {
  const history = useHistory();
  const params = useParams();
  const [assignment, setAssignment] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios.get(`${URL_ASSIGNMENT}${params.id}/`, options)
      .then((res) => {
        setAssignment(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <ButtonToolbar className="form__button-toolbar">
          <Button color="danger" onClick={history.goBack}>
            Kembali
          </Button>
        </ButtonToolbar>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Edit Tugas {assignment != null && assignment.title}</h5>
            {/* <h5 className="subhead">Use default tabs with classes
            <span className="red-text"> tabs--justify tabs--bordered-bottom</span>
          </h5> */}
          </div>
          <div className="tabs tabs--justify tabs--bordered-bottom">
            <EditAssigntmentForm />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default EditAssignment;
