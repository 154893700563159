/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/react-in-jsx-scope */
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
// import { Button } from 'reactstrap';
// import { useHistory } from 'react-router-dom';

// const PhotoFormatter = (value) => (
//   <div className="products-list__img-wrap">
//     <img src={value} alt="" />
//   </div>
// );
const topicEdit = (history, info) => {
  history.push({
    pathname: `/admin/student-stats/${info.iduser}`,
    state: { data: info },
  });
};

// const materialDetail = (history, info) => {
//   history.push({
//     pathname: `/admin/course-material/${info.id}`,
//     state: { data: info },
//   });
// };

const ListDataStudentList = (dataAPI) => {
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: 'No',
        accessor: 'number',
        width: 20,
        disableSortBy: true,
      },
      {
        Header: 'Email Siswa',
        accessor: 'email',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Nama Siswa',
        accessor: 'name',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Sudah Punya Sertifikat Kelas',
        accessor: 'isCertificate',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Statistik',
        accessor: 'stats',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
    ], [],
  );

  const data = [];
  const rows = () => {
    if (!dataAPI) {
      data.push({
        number: '-',
      });
    } else {
      for (let i = 0; i < dataAPI.length; i += 1) {
        if (dataAPI.length === 0) {
          data.push({
            number: '-',
          });
        } else {
          data.push({
            id: dataAPI[i].id,
            number: `${i + 1}`,
            email: `${dataAPI[i].email ? dataAPI[i].email : '-'}`,
            name: `${dataAPI[i].name ? dataAPI[i].name : '-'}`,
            isCertificate: <div className="course-topic-certificate">{dataAPI[i].isCertificate === false ? '✘' : '✔'}</div>,
            stats: [
              (<Button key={dataAPI[i].id} color="outline-success" onClick={() => topicEdit(history, dataAPI[i])}>Lihat</Button>),
            ],
          });
        }
      }
    }
  };

  rows();
  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default ListDataStudentList;
