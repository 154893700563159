/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Modal, ModalHeader, ModalBody, ModalFooter, Progress, Row,
} from 'reactstrap';
import { URL_QUIZ } from '../../../../../../utils/EndPoints';
import { LOCALSTORAGE_TOKEN } from '../../../../../../utils/Types';
import renderCheckBoxField from '../../../../../../shared/components/form/CheckBox';

const EditQuizForm = (stateOri) => {
  const history = useHistory();
  const params = useParams();

  // const editorRef = useRef(null);
  const [isEditable, setEditable] = useState(false);
  const [progress, setProgress] = useState();
  // const [quiz, setQuiz] = useState(null);

  // loading
  const [loading, setLoading] = useState(false);

  const { location } = history;
  const { state } = location;
  const { data } = state;
  const { initialize } = stateOri;
  const [confirm, setConfirm] = useState(0);
  const [modal, setModal] = useState(false);
  const toggle = (id) => {
    setConfirm(id);
    setModal(!modal);
  };

  useEffect(() => {
    initialize(data);
  }, []);

  return (
    <Col md={12} lg={12}>
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <form
            className="form"
            onSubmit={(e) => {
              e.preventDefault();
              const data = new FormData();
              data.append('name', e.target.name.value);
              data.append('isFinalQuiz', e.target.isFinalQuiz.checked);
              // data.append('topicId', data.topicId);
              const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
              const options = {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${token}`,
                },
                onUploadProgress: (data) => {
                  setProgress(Math.round((100 * data.loaded) / data.total));
                },
              };
              setLoading(true);
              axios.patch(`${URL_QUIZ}${params.id}`, data, options)
                .then((res) => {
                  history.push(`/admin/course-material/${res.data.topicId}`);
                  setLoading(false);
                })
                .catch((err) => {
                  console.log(err);
                  setLoading(false);
                });
            }}
          >
            <span className="form__form-group-label">Judul Kuis</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="name"
                  disabled={!isEditable}
                  component="input"
                  type="text"
                />
              </div>
            </div>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="isFinalQuiz"
                  disabled={!isEditable}
                  component={renderCheckBoxField}
                  label="Apakah Kuis Final?"
                />
              </div>
            </div>
            {loading && (
              <Card>
                <Col md={12} lg={12}>
                  <Progress animated value={progress}>{progress}%</Progress>
                </Col>
              </Card>
            )}
            <ButtonToolbar className="form__button-toolbar">
              <Row className="mt-4">
                <Col md={12}>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setEditable(!isEditable)}
                    color={isEditable && 'danger'}
                  >
                    {' '}
                    {!isEditable ? 'Ubah' : 'Batal'}
                  </button>
                  {isEditable && (
                    <button
                      type="submit"
                      className="btn btn-primary"
                    >
                      {' '}
                      Simpan Perubahan
                    </button>
                  )}
                  {isEditable === false && (
                    <button
                      type="button"
                      size="sm"
                      onClick={() => toggle(data.id)}
                      className="btn btn-danger"
                    >
                      Hapus
                    </button>
                  )}
                  <Modal
                    toggle={toggle}
                    isOpen={modal}
                    backdrop={false}
                  >
                    <ModalHeader toggle={toggle}>
                      Hapus Kuis
                    </ModalHeader>
                    <ModalBody>
                      Apakah kamu yakin ingin menghapus kuis ini?
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        onClick={() => {
                          const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                          const options = {
                            headers: {
                              'Content-Type':
                            'application/json',
                              Authorization: `Token ${token}`,
                            },
                          };
                          axios
                            .delete(
                              `${URL_QUIZ}${confirm}`,
                              options,
                            )
                            .then(() => {
                              history.push(`/admin/course-material/${data.topicId}`);
                            })
                            .catch(() => {
                              console.log('error');
                            });
                        }}
                      >
                        Hapus
                      </Button>
                    </ModalFooter>
                  </Modal>
                </Col>
              </Row>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

// EditQuizForm.propTypes = {
//   onSubmit: PropTypes.func.isRequired,
// };

export default reduxForm({
  form: 'edit_quiz', // a unique identifier for this form
})(EditQuizForm);
