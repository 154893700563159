/* eslint-disable no-shadow */
/* eslint-disable no-console */
import React from 'react';
import {
  Container,
} from 'reactstrap';
import EditTeacherForm from './components/editTeacherForm';

const EditTeacher = () => (
  <Container className="dashboard">
    <EditTeacherForm />
  </Container>
);
export default EditTeacher;
