/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container, Row,
} from 'reactstrap';
import { LOCALSTORAGE_TOKEN } from '../../../../utils/Types';
import { URL_LIBRARY } from '../../../../utils/EndPoints';
import ELibraryListTable from './components/ELibraryList';
import ListDataELibrary from './components/listDataELibrary';

const ELibraryList = () => {
  const [eLibrary, seteLibrary] = useState([]);
  const listDataTable = ListDataELibrary(eLibrary);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios.get(URL_LIBRARY, options)
      .then((res) => {
        seteLibrary(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <ELibraryListTable
          listDataTable={listDataTable}
          isLoading={isLoading}
        />
      </Row>
    </Container>
  );
};
export default ELibraryList;
