/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import {
  Card, CardBody, Col, ButtonToolbar, Button,
} from 'reactstrap';
import Select from 'react-select';
import { URL_USERBYROLE } from '../../../../../utils/EndPoints';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/Types';

const CreateInstructorForm = ({ handleSubmit }) => {
  const [instructor, setInstructor] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(`${URL_USERBYROLE}Teacher/`, options)
      .then((res) => {
        setInstructor(
          res.data.map((item) => ({
            value: item.id,
            label: `${item.name}`,
          })),
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Col md={12} lg={12}>
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <form className="form" onSubmit={handleSubmit}>
            <span className="form__form-group-label">Guru</span>
            <div className="form__form-group">
              <div>
                <Select
                  name="teacherId"
                  options={instructor}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary: '#ff4861',
                      primary25: 'hsla(352, 100%, 64%, 0.25)',
                      primary50: 'hsla(352, 100%, 64%, 0.50)',
                    },
                  })}
                />
              </div>
            </div>
            <span className="form__form-group-label">Posisi</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="position"
                  component="input"
                  type="text"
                  placeholder="Posisi"
                  required
                />
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar">
              <Button
                color="primary"
                type="submit"
              // disabled={!selectedStudent}
              >
                Simpan
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

CreateInstructorForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'instructor_create', // a unique identifier for this form
})(CreateInstructorForm);
