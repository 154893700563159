/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Progress, Alert, Spinner,
} from 'reactstrap';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { URL_TEACHER_REGIS } from '../../../../utils/EndPoints';
import { LOCALSTORAGE_TOKEN, LOCALSTORAGE_USERDETAIL } from '../../../../utils/Types';

const MyAccountForm = (stateOri) => {
  const [progress, setProgress] = useState(null);
  const [isEditable, setEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isAlertOn, setAlert] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');
  const [errorGet, setErrorGet] = useState('');
  const history = useHistory();

  const ResponseHandler = (msg) => {
    setErrorMsg(` ${JSON.stringify(msg)}`);
    setLoading(false);
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
    }, 10000);
  };

  const { initialize } = stateOri;
  const userDetail1 = JSON.parse(
    localStorage.getItem(LOCALSTORAGE_USERDETAIL),
  );
  useEffect(() => {
    const userDetail = JSON.parse(
      localStorage.getItem(LOCALSTORAGE_USERDETAIL),
    );
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    setLoading(true);
    axios
      .get(`${URL_TEACHER_REGIS + userDetail.id}/`, options)
      .then((res) => {
        initialize(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setErrorGet(err.response.status);
        setLoading(true);
      });
  }, []);

  const onSubmit = (e) => {
    // setLoading(true);
    e.preventDefault();
    const data = new FormData();
    data.append('name', e.target.name.value);
    data.append('email', e.target.email.value);
    data.append('phone', e.target.phone.value);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: (data) => {
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    };
    axios
      .patch(`${URL_TEACHER_REGIS + userDetail1.id}/`, data, options)
      .then(() => {
        window.location.reload();
        // history.push('/admin/dashboard');
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setProgress(null);
        ResponseHandler(err.response.data.email);
        // setLoading(false);
      });
  };

  return (
    <Col md={12} lg={12}>
      <Card className="card--not-full-height">
        <ButtonToolbar className="form__button-toolbar">
          <Button color="danger" onClick={history.goBack}>
            Kembali
          </Button>
        </ButtonToolbar>
        <CardBody className="dashboard__booking-card">
          <div className="card__title">
            <h5 className="bold-text">Detail Akun</h5>
          </div>
          {errorGet !== '' && (
            <Card>
              <Col md={12} lg={12}>
                <Alert color="danger">Gagal mendapatkan data. [{errorGet}]</Alert>
              </Col>
            </Card>
          )}

          {loading ? <Spinner /> : (
            <form className="form" onSubmit={onSubmit}>
              <span className="form__form-group-label">Nama Lengkap</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="name"
                    component="input"
                    type="text"
                    placeholder="Tulis nama lengkap"
                    disabled={!isEditable}
                  />
                </div>
              </div>
              <span className="form__form-group-label">Email</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="email"
                    component="input"
                    type="email"
                    placeholder="Tulis Email"
                    disabled={!isEditable}
                  />
                </div>
              </div>
              <span className="form__form-group-label">No Telp</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="phone"
                    component="input"
                    type="text"
                    placeholder="No Telp"
                    disabled={!isEditable}
                  />
                </div>
              </div>
              {progress !== null && (
                <Card>
                  <Col md={12} lg={12}>
                    <Progress animated value={progress}>{progress}%</Progress>
                  </Col>
                </Card>
              )}

              {(isAlertOn && errorMsg !== '') && (
                <Card>
                  <Col md={12} lg={12}>
                    <Alert color="danger">Gagal mendapatkan data. {errorMsg}</Alert>
                  </Col>
                </Card>
              )}

              <ButtonToolbar className="form__button-toolbar">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setEditable(!isEditable)}
                  color={isEditable && 'danger'}
                >
                  {' '}
                  {!isEditable ? 'Ubah' : 'Batal'}
                </button>
                {isEditable && (
                  <button
                    type="submit"
                    className="btn btn-primary"
                  >
                    {' '}
                    Simpan Perubahan
                  </button>
                )}
              </ButtonToolbar>
            </form>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

// EditTeacherForm.propTypes = {
//   handleSubmit: PropTypes.func.isRequired,
// };

export default reduxForm({
  form: 'teacher_edittopic_create', // a unique identifier for this form
})(MyAccountForm);
