/* eslint-disable no-console */
/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Spinner, Progress, Alert,
} from 'reactstrap';
import axios from 'axios';
import Collapse from '../../../../../shared/components/Collapse';
import ReactTableBase from '../../../../../shared/components/table/ReactTableBase';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/Types';
import { URL_TEACHER_REGIS } from '../../../../../utils/EndPoints';
import CreateTeacherForm from '../../Create/components/createTeacherForm';

const TeacherListTable = ({ listDataTable, isLoading }) => {
  const tableConfig = {
    isEditable: false,
    isSortable: true,
    isResizable: false,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
  };

  const [progress, setProgress] = useState();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isVisibleAlert, setVisibleAlert] = useState(false);

  const errorHandling = () => {
    setLoading(false);
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 5000);
  };

  const onSubmit = (e) => {
    // setLoading(true);
    e.preventDefault();
    const data = new FormData();
    data.append('name', e.target.name.value);
    data.append('email', e.target.email.value);
    data.append('password', e.target.password.value);
    data.append('about', e.target.content.value);
    if (e.target.profilePhoto.files[0]) {
      data.append('profilePhoto', e.target.profilePhoto.files[0]);
    }
    data.append('role', 'Teacher');
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: (dataProgress) => {
        setProgress(Math.round((100 * dataProgress.loaded) / dataProgress.total));
      },
    };
    setLoading(true);
    axios.post(URL_TEACHER_REGIS, data, options)
      .then(() => {
        window.location.reload();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response.data);
        setErrorMsg(err.response.data.email);
        errorHandling(err.response.data);
        setLoading(false);
      });
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="dashboard__booking-card">
          <div className="card__title">
            <h5 className="bold-text">List Guru</h5>
          </div>
          <Collapse title="Buat Akun Guru" className="with-shadow">
            <CreateTeacherForm
              handleSubmit={(e) => onSubmit(e)}
            />
          </Collapse>
          {isVisibleAlert ? (
            <Alert visible={isVisibleAlert} color="danger">
              <p><span className="bold-text">{errorMsg}</span></p>
            </Alert>
          ) : ''}
          {loading && (
            <Col md={12} lg={12}>
              <Progress animated value={progress}>{progress}%</Progress>
            </Col>
          )}
          {isLoading ? <Spinner /> : (
            <ReactTableBase
              columns={listDataTable.tableHeaderData}
              data={listDataTable.tableRowsData}
              tableConfig={tableConfig}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

TeacherListTable.propTypes = {
  listDataTable: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default TeacherListTable;
