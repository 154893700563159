/* eslint-disable radix */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Progress, Alert,
} from 'reactstrap';
import Select from 'react-select';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { URL_QUESTION, URL_QUESTION_BY_IDQUIZ } from '../../../../../../utils/EndPoints';
import { LOCALSTORAGE_TOKEN } from '../../../../../../utils/Types';

const CreateQuizQuestionForm = () => {
  const { id } = useParams();
  const [progress, setProgress] = useState(null);
  const [bobotCalculate, setBobotCalculate] = useState(0);
  const editorRef = useRef(null);

  // loading
  const [loading, setLoading] = useState(false);
  const [alertOn, setAlertOn] = useState(false);
  const [msg, setMsg] = useState('');

  const [content, setContents] = useState('');
  const handleEditorChange = (contents) => {
    setContents(contents);
  };

  // const optionsType = [
  //   { value: 'Pilihan Ganda', label: 'Pilihan Ganda' },
  // ];

  const optionsAnswer = [
    { value: 'A', label: 'A' },
    { value: 'B', label: 'B' },
    { value: 'C', label: 'C' },
    { value: 'D', label: 'D' },
  ];

  useEffect(() => {
    let currentBobot = 0;
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    setLoading(true);
    axios
      .get(`${URL_QUESTION_BY_IDQUIZ + id}/`, options)
      .then((res) => {
        console.log(res.data);
        res.data.map((q) => {
          currentBobot += q.bobot_correct;
        });
        setBobotCalculate(currentBobot);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const availableBobot = 100 - bobotCalculate;

  return (
    <Col md={12} lg={12}>
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <form
            className="form"
            onSubmit={(e) => {
              e.preventDefault();
              const data = new FormData();
              data.append('type', 'Pilihan Ganda');
              data.append('question_text', e.target.content.value);
              data.append('A', e.target.A.value);
              data.append('B', e.target.B.value);
              data.append('C', e.target.C.value);
              data.append('D', e.target.D.value);
              data.append('correct_answer', e.target.correct_answer.value);
              data.append('bobot_correct', e.target.bobot_correct.value);
              data.append('quizId', id);
              const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
              const options = {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${token}`,
                },
                onUploadProgress: (dataProgress) => {
                  setProgress(Math.round((100 * dataProgress.loaded) / dataProgress.total));
                },
              };
              setLoading(true);
              axios.post(URL_QUESTION, data, options)
                .then(() => {
                  window.location.reload();
                  setLoading(false);
                })
                .catch((err) => {
                  console.log(err);
                  setLoading(false);
                });
            }}
          >
            <input
              type="hidden"
              name="content"
              id=""
              value={content}
              defaultValue={content}
            />
            {/* <span className="form__form-group-label">Jenis Soal</span>
            <div className="form__form-group">
              <div className="form__form-group-field" style={{ display: 'block' }}>
                <Select
                  name="type"
                  options={optionsType}
                  defaultValue={optionsType[0]}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary: '#ff4861',
                      primary25: 'hsla(352, 100%, 64%, 0.25)',
                      primary50: 'hsla(352, 100%, 64%, 0.50)',
                    },
                  })}
                />
              </div>
            </div> */}
            <span className="form__form-group-label">Deskripsi Materi</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <input
                  id="my-file"
                  type="file"
                  name="my-file"
                  style={{ display: 'none' }}
                />
                <Editor
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  // apiKey="yb64t1vjyri2cansglhq8gaz1tai01e0dc0g3jlm9a4cjvdn"
                  apiKey="koceoiwytlyiwg8ifoagfwdyi3wvjg5fk88o3gakacslqc8y"
                  init={{
                    skin: 'snow',
                    icons: 'thin',
                    // placeholder:`${t('dashboard_teacher.create_course.description_message')}`,

                    height: 500,
                    menubar: true,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen textcolor ',
                      'insertdatetime media table paste code help wordcount',
                      'image code',
                    ],
                    image_title: true,
                    automatic_uploads: true,
                    file_picker_types: 'image',
                    textcolor_rows: '4',
                    file_picker_callback(callback, value, meta) {
                      if (meta.filetype === 'image') {
                        const input = document.getElementById('my-file');
                        input.click();
                        input.onchange = function () {
                          const file = input.files[0];
                          const reader = new FileReader();
                          reader.onload = function (e) {
                            callback(e.target.result, {
                              alt: file.name,
                            });
                          };
                          reader.readAsDataURL(file);
                        };
                      }
                    },

                    content_style:
                      "@import url('http://fonts.gstatic.com/s/nunito/v7/1TiHc9yag0wq3lDO9cw0vpBw1xU1rKptJj_0jans920.woff2');",
                    font_formats: 'Nunito',

                    toolbar:
                      'undo redo | styleselect | fontselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ',
                  }}
                  onEditorChange={handleEditorChange}
                  outputFormat="html"
                />
              </div>
            </div>
            <span className="form__form-group-label">A</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="A"
                  component="input"
                  type="text"
                  placeholder="Pilihan Jawaban A"
                  required
                />
              </div>
            </div>
            <span className="form__form-group-label">B</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="B"
                  component="input"
                  type="text"
                  placeholder="Pilihan Jawaban B"
                  required
                />
              </div>
            </div>
            <span className="form__form-group-label">C</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="C"
                  component="input"
                  type="text"
                  placeholder="Pilihan Jawaban C"
                  required
                />
              </div>
            </div>
            <span className="form__form-group-label">D</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="D"
                  component="input"
                  type="text"
                  placeholder="Pilihan Jawaban D"
                  required
                />
              </div>
            </div>
            <span className="form__form-group-label">Jawaban yang Benar</span>
            <div className="form__form-group">
              <div className="form__form-group-field" style={{ display: 'block' }}>
                <Select
                  name="correct_answer"
                  options={optionsAnswer}
                  defaultValue={optionsAnswer[0]}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary: '#ff4861',
                      primary25: 'hsla(352, 100%, 64%, 0.25)',
                      primary50: 'hsla(352, 100%, 64%, 0.50)',
                    },
                  })}
                />
              </div>
            </div>
            <span className="form__form-group-label">Bobot Nilai (maksimal bobot yang tersedia: <b>{availableBobot}</b>)</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="bobot_correct"
                  component="input"
                  type="number"
                  min="1"
                  max={`${availableBobot}`}
                  onChange={(e) => {
                    if (parseInt(e.target.value) > availableBobot) {
                      setAlertOn(true);
                      setMsg('Bobot Nilai yang dimasukkan tidak boleh melebihi dari bobot yang tersedia');
                    }
                    if (parseInt(e.target.value) === 0) {
                      setAlertOn(true);
                      setMsg('Bobot Nilai yang dimasukkan tidak boleh 0');
                    }
                    if (parseInt(e.target.value) < 0) {
                      setAlertOn(true);
                      setMsg('Bobot Nilai yang dimasukkan tidak boleh minus');
                    }
                    if (parseInt(e.target.value <= availableBobot)) {
                      setAlertOn(false);
                      setMsg('');
                    }
                    if (e.target.value === '') {
                      setAlertOn(false);
                      setMsg('');
                    }
                  }}
                  placeholder={`Bobot Nilai (maksimal bobot yang tersedia: ${availableBobot})`}
                  required
                />
              </div>
              {alertOn && (
                <div>
                  &nbsp;
                  <Alert
                    color="danger"
                  >
                    {msg}
                  </Alert>
                </div>
              )}
            </div>

            {loading && (
              <Card>
                <Col md={12} lg={12}>
                  <Progress animated value={progress}>{progress}%</Progress>
                </Col>
              </Card>
            )}

            <ButtonToolbar className="form__button-toolbar">
              <Button
                color="primary"
                type="submit"
              // disabled={!selectedStudent}
              >
                Simpan
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

// CreateQuizQuestionForm.propTypes = {
//   onSubmit: PropTypes.func.isRequired,
// };

export default reduxForm({
  form: 'create_question', // a unique identifier for this form
})(CreateQuizQuestionForm);
