/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Modal, ModalHeader, ModalBody, ModalFooter, Progress, Alert,
} from 'reactstrap';
import { RiErrorWarningLine } from 'react-icons/ri';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { URL_TEACHER_REGIS, URL_USERS } from '../../../../../utils/EndPoints';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/Types';
import renderFileInputField from '../../../../../shared/components/form/FileInput';

const EditTeacherForm = (stateOri) => {
  const params = useParams();
  const [progress, setProgress] = useState(null);
  const [isEditable, setEditable] = useState(false);
  const history = useHistory();
  const { location } = history;
  const { state } = location;
  const { initialize } = stateOri;
  const { data } = state;
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  const editorRef = useRef(null);
  // Tinymce Editor
  const [content, setContents] = useState('');
  const handleEditorChange = (contents) => {
    setContents(contents);
  };
  const [file, setFile] = useState(null);
  const [alertPhoto, setAlertPhoto] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isVisibleAlert, setVisibleAlert] = useState(false);

  const errorHandling = () => {
    setLoading(false);
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 5000);
  };

  useEffect(() => {
    initialize(data);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(`${URL_TEACHER_REGIS + params.id}/`, options)
      .then((res) => {
        if (res.data.profilePhoto) {
          setFile(res.data.profilePhoto);
        }
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  }, []);

  const onSubmit = (e) => {
    // setLoading(true);
    e.preventDefault();
    const data = new FormData();
    data.append('name', e.target.name.value);
    data.append('email', e.target.email.value);
    data.append('about', e.target.content.value);
    if (e.target.profilePhoto.files[0]) {
      data.append('profilePhoto', e.target.profilePhoto.files[0]);
    }
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: (data) => {
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    };
    setLoading(true);
    axios
      .patch(`${URL_TEACHER_REGIS + params.id}/`, data, options)
      .then(() => {
        history.push('/admin/teacher');
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setErrorMsg(err.response.data.email);
        errorHandling(err.response.data);
        setLoading(false);
      });
  };

  const imageHandler = () => {
    if (file) {
      return file;
    } if (data.profilePoto) {
      return data.profilePoto;
    }
    return '/img/no_image.png';
  };

  const inputImageHandler = (e) => {
    setFile(URL.createObjectURL(e.file));
    const conditions = ['image/png', 'image/jpg', 'image/jpeg'];

    if (conditions.some((el) => e.file.type.includes(el))) {
      setAlertPhoto(false);
    } else {
      setAlertPhoto(true);
    }
  };

  return (
    <Col md={12} lg={12}>
      <ButtonToolbar className="form__button-toolbar">
        <Button color="danger" onClick={history.goBack}>
          Kembali
        </Button>
      </ButtonToolbar>
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <div className="card__title">
            <h5 className="bold-text">Detail Guru</h5>
          </div>
          <form className="form" onSubmit={onSubmit}>
            <input
              type="hidden"
              name="content"
              value={content}
              // defaultValue={content}
            />
            <img src={imageHandler()} alt="" className="testimonial-image" />
            <span className="form__form-group-label">Foto Guru</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="profilePhoto"
                  component={renderFileInputField}
                  accept="image/x-png,image/jpeg"
                  onChange={(e) => { inputImageHandler(e); }}
                />
              </div>
              {alertPhoto && (
                <span className="text-red"><RiErrorWarningLine /> Mohon isi dengan type image (.png, .jpeg, .jpg)</span>
              )}
            </div>
            <span className="form__form-group-label">Nama Lengkap</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="name"
                  component="input"
                  type="text"
                  placeholder="Tulis nama lengkap"
                  disabled={!isEditable}
                />
              </div>
            </div>
            <span className="form__form-group-label">Email</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="email"
                  component="input"
                  type="text"
                  placeholder="Tulis Email"
                  disabled={!isEditable}
                />
              </div>
            </div>
            <span className="form__form-group-label">About</span>
            <div className="form__form-group">
              <div className="form__form-group-field w-100-role-application">
                <input
                  id="my-file"
                  type="file"
                  name="my-file"
                  style={{ display: 'none' }}
                />
                <Editor
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  apiKey="koceoiwytlyiwg8ifoagfwdyi3wvjg5fk88o3gakacslqc8y"
                  initialValue={data.about}
                  init={{
                    skin: 'snow',
                    icons: 'thin',

                    height: 500,
                    menubar: true,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen textcolor ',
                      'insertdatetime media table paste code help wordcount',
                      'image code',
                    ],
                    image_title: true,
                    automatic_uploads: true,
                    file_picker_types: 'image',
                    textcolor_rows: '4',
                    file_picker_callback(callback, value, meta) {
                      if (meta.filetype === 'image') {
                        const input = document.getElementById('my-file');
                        input.click();
                        input.onchange = function () {
                          const file = input.files[0];
                          const reader = new FileReader();
                          reader.onload = function (e) {
                            callback(e.target.result, {
                              alt: file.name,
                            });
                          };
                          reader.readAsDataURL(file);
                        };
                      }
                    },

                    content_style:
                      "@import url('http://fonts.gstatic.com/s/nunito/v7/1TiHc9yag0wq3lDO9cw0vpBw1xU1rKptJj_0jans920.woff2');",
                    font_formats: 'Nunito',

                    toolbar:
                      'undo redo | styleselect | fontselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ',
                  }}
                  onEditorChange={handleEditorChange}
                  outputFormat="html"
                />
              </div>
            </div>

            {isVisibleAlert ? (
              <Alert visible={isVisibleAlert} color="danger">
                <p><span className="bold-text">{errorMsg}</span></p>
              </Alert>
            ) : ''}
            {loading && (
            <Col md={12} lg={12}>
              <Progress animated value={progress}>{progress}%</Progress>
            </Col>
            )}
            <ButtonToolbar className="form__button-toolbar">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setEditable(!isEditable)}
                color={isEditable ? 'danger' : 'primary'}
              >
                {' '}
                {!isEditable ? 'Ubah' : 'Batal'}
              </button>
              {isEditable && (
                <button
                  type="submit"
                  className="btn btn-primary"
                >
                  {' '}
                  Simpan Perubahan
                </button>
              )}
              {isEditable === false && (
                <button
                  type="button"
                  size="sm"
                  onClick={() => toggle()}
                  className="btn btn-danger"
                >
                  Hapus
                </button>
              )}
              <Modal
                toggle={toggle}
                isOpen={modal}
                backdrop={false}
              >
                <ModalHeader toggle={toggle}>
                  Hapus Guru
                </ModalHeader>
                <ModalBody>
                  Apakah kamu yakin ingin menghapus Guru ini?
                </ModalBody>
                <ModalFooter>
                  <Button
                    onClick={() => {
                      const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                      const options = {
                        headers: {
                          'Content-Type':
                            'application/json',
                          Authorization: `Token ${token}`,
                        },
                      };
                      axios
                        .delete(
                          `${URL_USERS}${data.id}/`,
                          options,
                        )
                        .then(() => {
                          history.push('/admin/teacher');
                        })
                        .catch(() => {
                          console.log('error');
                        });
                    }}
                  >
                    Hapus
                  </Button>
                </ModalFooter>
              </Modal>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

// EditTeacherForm.propTypes = {
//   handleSubmit: PropTypes.func.isRequired,
// };

export default reduxForm({
  form: 'teacher_edit', // a unique identifier for this form
})(EditTeacherForm);
