/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Panel from '../../../../../../shared/components/Panel';
import getTooltipStyles from '../../../../../../shared/helpers';
import { LOCALSTORAGE_TOKEN } from '../../../../../../utils/Types';
import { URL_USERSTATS } from '../../../../../../utils/EndPoints';

const Chart = ({ themeName }) => {
  const [historyLogin, setHistoryLogin] = useState([]);
  const params = useParams();

  useEffect(() => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios.get(`${URL_USERSTATS}${params.id}/course/50`, options)
      .then((res) => {
        console.log('res', res.data);
        setHistoryLogin(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const data = historyLogin !== null && historyLogin.map((e) => ({
    date: e.date, total: e.rangeStudy === null ? 0 : e.rangeStudy,
  }));
  return (
    <Panel md={12} lg={12} xl={12} title="Statistik Peserta Per Bulan">
      <div dir="ltr">
        <ResponsiveContainer height={250} className="dashboard__area">
          <AreaChart data={data} margin={{ top: 20, left: -15, bottom: 20 }}>
            <XAxis dataKey="date" tickLine={false} />
            <YAxis tickLine={false} orientation="right" />
            <Tooltip {...getTooltipStyles(themeName, 'defaultItems')} />
            <Legend />
            <CartesianGrid />
            <Area name="Durasi" type="monotone" dataKey="total" fill="red" stroke="red" fillOpacity={0.2} />
            {/* <Area name="Site B" type="monotone" dataKey="b" fill="#70bbfd" stroke="#70bbfd" fillOpacity={0.2} /> */}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </Panel>
  );
};
Chart.propTypes = {
  themeName: PropTypes.string.isRequired,
};

export default connect((state) => ({ themeName: state.theme.className }))(Chart);
