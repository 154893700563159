/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
// import SidebarCategory from './SidebarCategory';

const SidebarContent = ({ onClick }) => {
  const handleHideSidebar = () => {
    onClick();
  };

  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        <SidebarLink title="Dashboard" route="/admin/dashboard" icon="home" onClick={handleHideSidebar} />
        <SidebarLink title="Kelas" route="/admin/course" icon="book" onClick={handleHideSidebar} />
        <SidebarLink title="Guru" route="/admin/teacher" icon="graduation-hat" onClick={handleHideSidebar} />
        <SidebarLink title="User" route="/admin/user" icon="users" onClick={handleHideSidebar} />
        {/* <SidebarLink title="Event" route="/admin/event" onClick={handleHideSidebar} /> */}
        <SidebarLink title="E-Library" route="/admin/e-library" icon="bookmark" onClick={handleHideSidebar} />
        <SidebarLink title="Testimoni" route="/admin/testimonial" icon="inbox" onClick={handleHideSidebar} />
        <SidebarLink title="Widyaiswara" route="/admin/video-content" icon="film-play" onClick={handleHideSidebar} />
        {/* <SidebarCategory title="Layout" icon="layers">
          <button type="button" className="sidebar__link" onClick={changeToLight}>
            <p className="sidebar__link-title">Light Theme</p>
          </button>
          <button type="button" className="sidebar__link" onClick={changeToDark}>
            <p className="sidebar__link-title">Dark Theme</p>
          </button>
        </SidebarCategory> */}
      </ul>
      {/* <ul className="sidebar__block">
        <SidebarCategory title="Example Pages" icon="diamond">
          <SidebarLink title="Page one" route="/pages/one" onClick={handleHideSidebar} />
          <SidebarLink title="Page two" route="/pages/two" onClick={handleHideSidebar} />
        </SidebarCategory>
      </ul> */}
    </div>
  );
};

SidebarContent.propTypes = {
  // changeToDark: PropTypes.func.isRequired,
  // changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SidebarContent;
