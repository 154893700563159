/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';
import AccountTieIcon from 'mdi-react/AccountTieIcon';
import BookVariant from 'mdi-react/BookVariantIcon';
import CertificateIcon from 'mdi-react/CertificateIcon';
import axios from 'axios';
import DescriptionCard from './components/DescriptionCard';
import { LOCALSTORAGE_TOKEN } from '../../../utils/Types';
import {
  URL_CERTIFICATE,
  URL_COURSE,
  URL_USERBYROLE,
} from '../../../utils/EndPoints';
import Chart from './components/chart';

const HomeAdmin = () => {
  const [course, setCourse] = useState([]);
  const [teacher, setTeacher] = useState([]);
  const [user, setUser] = useState([]);
  const [certificate, setCertificate] = useState([]);
  const [classIsLoading, setClassIsLoading] = useState(false);
  const [teacherIsLoading, setTeacherIsLoading] = useState(false);
  const [userIsLoading, setUserIsLoading] = useState(false);
  const [certificateIsLoading, setCertificateIsLoading] = useState(false);
  const [errorClass, setErrorClass] = useState('');
  const [errorTeacher, setErrorTeacher] = useState('');
  const [errorUser, setErrorUser] = useState('');
  const [errorCertificate, setErrorCertificate] = useState('');

  useEffect(() => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);

    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    setClassIsLoading(true);
    axios
      .get(URL_COURSE, options)
      .then((res) => {
        setCourse(res.data);
        setClassIsLoading(false);
      })
      .catch((err) => {
        setErrorClass(err.response.status);
        setClassIsLoading(false);
      });

    setUserIsLoading(true);
    axios
      .get(`${URL_USERBYROLE}Student/`, options)
      .then((res) => {
        setUser(res.data);
        setUserIsLoading(false);
      })
      .catch((err) => {
        setErrorUser(err.response.status);
        setUserIsLoading(false);
      });

    setTeacherIsLoading(true);
    axios
      .get(`${URL_USERBYROLE}Teacher/`, options)
      .then((res) => {
        setTeacher(res.data);
        setTeacherIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setErrorTeacher(err.response.status);
        setTeacherIsLoading(false);
      });

    setCertificateIsLoading(true);
    axios
      .get(URL_CERTIFICATE, options)
      .then((res) => {
        setCertificate(res.data);
        setCertificateIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setErrorCertificate(err.response.status);
        setCertificateIsLoading(false);
      });
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Dashboard Admin</h3>
        </Col>
      </Row>
      <Row>
        <DescriptionCard
          title="Total Peserta"
          icon={
            <AccountCircleIcon className="dashboard__trend-icon dashboard-account-icon" />
          }
          loading={userIsLoading}
          total={user.length.toString()}
          error={errorUser}
        />
        <DescriptionCard
          title="Total Kelas"
          icon={
            <BookVariant className="dashboard__trend-icon dashboard-account-icon" />
          }
          loading={classIsLoading}
          total={course.length.toString()}
          error={errorClass}
        />
        <DescriptionCard
          title="Total Guru"
          icon={
            <AccountTieIcon className="dashboard__trend-icon dashboard-account-icon" />
          }
          loading={teacherIsLoading}
          total={teacher.length.toString()}
          error={errorTeacher}
        />
        <DescriptionCard
          title="Total Sertifikat"
          icon={
            <CertificateIcon className="dashboard__trend-icon dashboard-account-icon" />
          }
          loading={certificateIsLoading}
          total={certificate.length.toString()}
          error={errorCertificate}
        />
      </Row>
      <Row>
        <Chart />
      </Row>
    </Container>
  );
};

export default HomeAdmin;
