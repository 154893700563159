/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/react-in-jsx-scope */
import
// React,
{ useMemo } from 'react';
// import { Button } from 'reactstrap';
// import { useHistory } from 'react-router-dom';

// const PhotoFormatter = (value) => (
//   <div className="products-list__img-wrap">
//     <img src={value} alt="" />
//   </div>
// );
// const topicEdit = (history, info) => {
//   history.push({
//     pathname: `/admin/topic-edit/${info.id}`,
//     state: { data: info },
//   });
// };

// const materialDetail = (history, info) => {
//   history.push({
//     pathname: `/admin/course-material/${info.id}`,
//     state: { data: info },
//   });
// };

const ListDataAttendance = (dataAPI) => {
  // const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: 'No',
        accessor: 'number',
        width: 20,
        disableSortBy: true,
      },
      {
        Header: 'Nama Siswa',
        accessor: 'name',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Jam Masuk',
        accessor: 'inHour',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Jam Keluar',
        accessor: 'outHour',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Durasi Belajar',
        accessor: 'rangeStudy',
        disableSortBy: true,
        width: 110,
      },
      // {
      //   Header: 'Status',
      //   accessor: 'isAttendance',
      //   disableSortBy: true,
      //   width: 110,
      // },
      // {
      //   Header: 'Aksi',
      //   accessor: 'action',
      //   disableGlobalFilter: true,
      //   disableSortBy: true,
      //   width: 110,
      // },
    ], [],
  );

  const data = [];
  const rows = () => {
    if (!dataAPI) {
      data.push({
        number: '-',
      });
    } else {
      for (let i = 0; i < dataAPI.length; i += 1) {
        if (dataAPI.length === 0) {
          data.push({
            number: '-',
          });
        } else {
          data.push({
            id: dataAPI[i].id,
            number: `${i + 1}`,
            name: dataAPI[i].name,
            inHour: dataAPI[i].inHour !== null ? dataAPI[i].inHour : '-',
            outHour: dataAPI[i].outHour !== null ? dataAPI[i].outHour : '-',
            rangeStudy: dataAPI[i].rangeStudy !== null ? dataAPI[i].rangeStudy : '-',
            // isAttendance: dataAPI[i].isAttendance,
            // action: [
            //   // eslint-disable-next-line no-loop-func
            //   // eslint-disable-next-line max-len
            //   (<Button color="success" onClick={() => topicEdit(history, dataAPI[i])}>Detail</Button>),
            //   (<Button color="success" onClick={() => materialDetail(history, dataAPI[i])}>Material</Button>),
            // ],
          });
        }
      }
    }
  };

  rows();
  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default ListDataAttendance;
