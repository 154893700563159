import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import LogIn from '../Account/LogIn';
import HomeAdmin from '../Admin/HomePage';
import CourseList from '../Admin/Course/List';
import CreateCourse from '../Admin/Course/Create';
import CourseTopic from '../Admin/Course/Topic';
import CourseDetail from '../Admin/Course/Detail/Edit';
import EditTopic from '../Admin/Course/Topic/Edit';
import CourseMaterial from '../Admin/Course/Material';
import EditMaterial from '../Admin/Course/Material/Edit';
import EditQuiz from '../Admin/Course/Quiz/Edit';
import QuizQuestion from '../Admin/Course/Quiz/Question';
import EditQuizQuestion from '../Admin/Course/Quiz/Question/Edit';
import EditInstructor from '../Admin/Course/Instructor/Edit';
import ELibraryList from '../Admin/E-Library/List';
import CreateELibrary from '../Admin/E-Library/Create';
import EditELibrary from '../Admin/E-Library/Detail';
import TestimonialList from '../Admin/Testimonial/List';
import CreateTestimonial from '../Admin/Testimonial/Create';
import EditTestimonial from '../Admin/Testimonial/Detail';
import VideoContentList from '../Admin/VideoContent/List';
import CreateVideoContent from '../Admin/VideoContent/Create';
import EditVideoContent from '../Admin/VideoContent/Detail';
import EditLiveSession from '../Admin/Course/LiveSession/Edit';
import QuizScore from '../Admin/Course/Quiz/Score';
import TeacherList from '../Admin/Teacher/List';
import EditAssignment from '../Admin/Course/Assignment/Edit';
import Submission from '../Admin/Course/Assignment/Submission';
import ResetPassword from '../Account/ForgotPassword/reset';
import PrivateRoute from '../../utils/PrivateRoute';
import NewPassword from '../Account/ForgotPassword/NewPassword';
import EditTeacher from '../Admin/Teacher/Detail';
import MyAccount from '../Account/MyAccount';
import SubmissionEdit from '../Admin/Course/Assignment/Submission/Edit';
import UserList from '../Admin/User/List';
import EditUser from '../Admin/User/Detail';
import StudentStats from '../Admin/Course/StudentList/StudentStats';

const Pages = () => (
  <Switch>
    <PrivateRoute path="/admin/dashboard" component={HomeAdmin} />
    <PrivateRoute path="/admin/course" component={CourseList} />
    <PrivateRoute path="/admin/course-add" component={CreateCourse} />
    <PrivateRoute path="/admin/course-topic/:id" component={CourseTopic} />
    <PrivateRoute path="/admin/student-stats/:id" component={StudentStats} />
    <PrivateRoute path="/admin/course-detail/:id" component={CourseDetail} />
    <PrivateRoute path="/admin/topic-edit/:id" component={EditTopic} />
    <PrivateRoute path="/admin/course-material/:id" component={CourseMaterial} />
    <PrivateRoute path="/admin/material-edit/:id" component={EditMaterial} />
    <PrivateRoute path="/admin/quiz-edit/:id" component={EditQuiz} />
    <PrivateRoute path="/admin/quiz-question/:id" component={QuizQuestion} />
    <PrivateRoute path="/admin/quiz-score/:id" component={QuizScore} />
    <PrivateRoute path="/admin/quiz-question-edit/:id" component={EditQuizQuestion} />
    <PrivateRoute path="/admin/livesession-edit/:id" component={EditLiveSession} />
    <PrivateRoute path="/admin/instructor-edit/:id" component={EditInstructor} />
    <PrivateRoute path="/admin/e-library" component={ELibraryList} />
    <PrivateRoute path="/admin/e-library-add" component={CreateELibrary} />
    <PrivateRoute path="/admin/e-library-detail/:id" component={EditELibrary} />
    <PrivateRoute path="/admin/testimonial" component={TestimonialList} />
    <PrivateRoute path="/admin/testimonial-add" component={CreateTestimonial} />
    <PrivateRoute path="/admin/testimonial-detail/:id" component={EditTestimonial} />
    <PrivateRoute path="/admin/video-content" component={VideoContentList} />
    <PrivateRoute path="/admin/video-content-add" component={CreateVideoContent} />
    <PrivateRoute path="/admin/video-content-detail/:id" component={EditVideoContent} />
    <PrivateRoute path="/admin/teacher" component={TeacherList} />
    <PrivateRoute path="/admin/assignment-edit/:id" component={EditAssignment} />
    <PrivateRoute path="/admin/assignment-submission/:id" component={Submission} />
    <PrivateRoute path="/admin/submission-detail/:id" component={SubmissionEdit} />
    <PrivateRoute path="/admin/teacher-detail/:id" component={EditTeacher} />
    <PrivateRoute path="/admin/myaccount" component={MyAccount} />
    <PrivateRoute path="/admin/user" component={UserList} />
    <PrivateRoute path="/admin/user-detail/:id" component={EditUser} />
  </Switch>
);

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <PrivateRoute path="/admin/" component={Pages} />
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/" component={LogIn} />
        <Route exact path="/log_in" component={LogIn} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/new-password" component={NewPassword} />
        <Route path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
