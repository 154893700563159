/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container, Row,
} from 'reactstrap';
import { useParams } from 'react-router-dom';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/Types';
import { URL_SCORE_BY_IDQUIZ, URL_SUBMISSION_BY_IDASSIGNMENT } from '../../../../../utils/EndPoints';
import SubmissionListTable from './components/submissionList';
import ListDataSubmission from './components/listDataSubmission';

const Submission = () => {
  const [submission, setSubmission] = useState([]);
  const listDataTable = ListDataSubmission(submission);
  const [isLoading, setLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios.get(`${URL_SUBMISSION_BY_IDASSIGNMENT}${id}`, options)
      .then((res) => {
        setSubmission(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <Container className="dashboard">
      {/* <ButtonToolbar className="form__button-toolbar">
        <Button color="primary" onClick={history.goBack}>
          Back
        </Button>
      </ButtonToolbar> */}
      <Row>
        <SubmissionListTable
          listDataTable={listDataTable}
          isLoading={isLoading}
        />
      </Row>
    </Container>
  );
};
export default Submission;
