/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Progress,
} from 'reactstrap';
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { RiErrorWarningLine } from 'react-icons/ri';
import {
  LOCALSTORAGE_TOKEN,
  LOCALSTORAGE_USERDETAIL,
} from '../../../../../utils/Types';
import renderCheckBoxField from '../../../../../shared/components/form/CheckBox';
import renderFileInputField from '../../../../../shared/components/form/FileInput';
import {
  URL_CATEGORYCOURSE,
  URL_COURSE,
  URL_SUBCATEGORYCOURSEBYCATEGORY,
} from '../../../../../utils/EndPoints';

const CreateCourseForm = () => {
  const history = useHistory();
  const [progress, setProgress] = useState(null);
  const editorRef = useRef(null);

  // list select option
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  // alert
  const [alertCategoriesImg, setAlertCategoriesImage] = useState(false);
  const [alertCoursesImg, setAlertCoursesImg] = useState(false);
  const [alertCoursesSmallImg, setAlertCoursesSmallImg] = useState(false);
  const [alertCoursesVideo, setAlertCoursesVideo] = useState(false);

  // loading
  const [loading, setLoading] = useState(false);

  // Tinymce Editor
  const [content, setContents] = useState('');
  const handleEditorChange = (contents) => {
    setContents(contents);
  };

  useEffect(() => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(URL_CATEGORYCOURSE, options)
      .then((res) => {
        setCategories(
          res.data.map((category) => ({
            value: category.id,
            label: category.name,
          })),
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    const userDetail = JSON.parse(
      localStorage.getItem(LOCALSTORAGE_USERDETAIL),
    );
    const data = new FormData();
    data.append('userId', userDetail.id);
    data.append('title', e.target.title.value);
    data.append('duration', e.target.duration.value);
    data.append('lesson_hours', e.target.lesson_hours.value);
    if (e.target.categories_img.files[0]) {
      data.append('categories_img', e.target.categories_img.files[0]);
    }
    if (e.target.courses_img.files[0]) {
      data.append('courses_img', e.target.courses_img.files[0]);
    }
    if (e.target.courses_small_img.files[0]) {
      data.append('courses_small_img', e.target.courses_small_img.files[0]);
    }
    if (e.target.course_preview_video.files[0]) {
      data.append(
        'course_preview_video',
        e.target.course_preview_video.files[0],
      );
    }
    if (e.target.category.value) {
      data.append('category', e.target.category.value);
    }
    if (e.target.subcategory.value) {
      data.append('subcategory', e.target.subcategory.value);
    }
    data.append('overview', e.target.content.value);
    data.append('isPublish', e.target.isPublish.checked);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: (data) => {
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    };

    setLoading(true);
    axios
      .post(URL_COURSE, data, options)
      .then(() => {
        history.push('/admin/course');
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Col md={12} lg={12}>
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <div className="card__title">
            <h5 className="bold-text">Buat Kelas Baru</h5>
          </div>
          <form className="form" onSubmit={onSubmit}>
            <input
              type="hidden"
              name="content"
              id=""
              value={content}
              defaultValue={content}
            />

            <span className="form__form-group-label">Judul</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="title"
                  component="input"
                  type="text"
                  placeholder="Judul Kelas"
                  required
                />
              </div>
            </div>

            <span className="form__form-group-label">Kategori Kelas</span>
            <div className="form__form-group">
              <div className="form__form-group-field" style={{ display: 'block' }}>
                <Select
                  name="category"
                  options={categories}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary: '#ff4861',
                      primary25: 'hsla(352, 100%, 64%, 0.25)',
                      primary50: 'hsla(352, 100%, 64%, 0.50)',
                    },
                  })}
                  onChange={(e) => {
                    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                    const options = {
                      headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${token}`,
                      },
                    };
                    axios
                      .get(
                        `${URL_SUBCATEGORYCOURSEBYCATEGORY + e.value}/`,
                        options,
                      )
                      .then((res) => {
                        setSubcategories(
                          res.data.map((category) => ({
                            value: category.id,
                            label: category.name,
                          })),
                        );
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }}
                  required
                />
              </div>
            </div>

            <span className="form__form-group-label">Sub Kategori Kelas</span>
            <div className="form__form-group">
              <div className="form__form-group-field" style={{ display: 'block' }}>
                <Select
                  name="subcategory"
                  options={subcategories}
                  required
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary: '#ff4861',
                      primary25: 'hsla(352, 100%, 64%, 0.25)',
                      primary50: 'hsla(352, 100%, 64%, 0.50)',
                    },
                  })}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <span className="form__form-group-label">Kategori Thumbnail</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="categories_img"
                    component={renderFileInputField}
                    onChange={(e) => {
                      if (!e.file.type.includes('image/png' || 'image/jpg' || 'image/jpeg')) {
                        setAlertCategoriesImage(true);
                      } else {
                        setAlertCategoriesImage(false);
                      }
                    }}
                    required
                  />
                </div>
                {alertCategoriesImg && (
                  <span style={{ color: 'red' }}><RiErrorWarningLine /> Mohon isi dengan type file image</span>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <span className="form__form-group-label">Gambar Kelas</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="courses_img"
                    component={renderFileInputField}
                    onChange={(e) => {
                      if (!e.file.type.includes('image/png' || 'image/jpg' || 'image/jpeg')) {
                        setAlertCoursesImg(true);
                      } else {
                        setAlertCoursesImg(false);
                      }
                    }}
                    required
                  />
                </div>
                {alertCoursesImg && (
                  <span style={{ color: 'red' }}><RiErrorWarningLine /> Mohon isi dengan type file image</span>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <span className="form__form-group-label">Gambar Kecil Kelas</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="courses_small_img"
                    component={renderFileInputField}
                    onChange={(e) => {
                      if (!e.file.type.includes('image/png' || 'image/jpg' || 'image/jpeg')) {
                        setAlertCoursesSmallImg(true);
                      } else {
                        setAlertCoursesSmallImg(false);
                      }
                    }}
                    required
                  />
                </div>
                {alertCoursesSmallImg && (
                  <span style={{ color: 'red' }}><RiErrorWarningLine /> Mohon isi dengan type file image</span>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <span className="form__form-group-label">Video Pratinjau</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="course_preview_video"
                    component={renderFileInputField}
                    onChange={(e) => {
                      if (!e.file.type.includes('video')) {
                        setAlertCoursesVideo(true);
                      } else {
                        setAlertCoursesVideo(false);
                      }
                    }}
                    required
                  />
                </div>
                {alertCoursesVideo && (
                  <span style={{ color: 'red' }}><RiErrorWarningLine /> Mohon isi dengan type file video</span>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <span className="form__form-group-label">Durasi Menit</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="duration"
                    component="input"
                    type="text"
                    placeholder="Durasi Menit (ex: 120)"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <span className="form__form-group-label">Jam Pelajaran (JP)</span>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="lesson_hours"
                    component="input"
                    type="text"
                    placeholder="Jam Pelajaran (ex: 10)"
                    required
                  />
                </div>
              </div>
            </div>
            <span className="form__form-group-label">Deskripsi Kelas</span>
            <div className="form__form-group">
              <div className="form__form-group-field w-100-role-application">
                <input
                  id="my-file"
                  type="file"
                  name="my-file"
                  style={{ display: 'none' }}
                />
                <Editor
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  // apiKey="yb64t1vjyri2cansglhq8gaz1tai01e0dc0g3jlm9a4cjvdn"
                  apiKey="koceoiwytlyiwg8ifoagfwdyi3wvjg5fk88o3gakacslqc8y"
                  init={{
                    skin: 'snow',
                    icons: 'thin',
                    // placeholder:`${t('dashboard_teacher.create_course.description_message')}`,

                    height: 500,
                    menubar: true,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen textcolor ',
                      'insertdatetime media table paste code help wordcount',
                      'image code',
                    ],
                    image_title: true,
                    automatic_uploads: true,
                    file_picker_types: 'image',
                    textcolor_rows: '4',
                    file_picker_callback(callback, value, meta) {
                      if (meta.filetype === 'image') {
                        const input = document.getElementById('my-file');
                        input.click();
                        input.onchange = function () {
                          const file = input.files[0];
                          const reader = new FileReader();
                          reader.onload = function (e) {
                            callback(e.target.result, {
                              alt: file.name,
                            });
                          };
                          reader.readAsDataURL(file);
                        };
                      }
                    },

                    content_style:
                      "@import url('http://fonts.gstatic.com/s/nunito/v7/1TiHc9yag0wq3lDO9cw0vpBw1xU1rKptJj_0jans920.woff2');",
                    font_formats: 'Nunito',

                    toolbar:
                      'undo redo | styleselect | fontselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ',
                  }}
                  onEditorChange={handleEditorChange}
                  outputFormat="html"
                />
              </div>
            </div>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="isPublish"
                  component={renderCheckBoxField}
                  label="Apakah kelas ini ingin ditampilkan di homepage ?"
                />
              </div>
            </div>

            {loading && (
              <Card>
                <Col md={12} lg={12}>
                  <Progress animated value={progress}>{progress}%</Progress>
                </Col>
              </Card>
            )}

            <ButtonToolbar className="form__button-toolbar">
              <Button
                color="primary"
                type="submit"
                disabled={alertCategoriesImg || alertCoursesImg || alertCoursesSmallImg || alertCoursesVideo}
              >
                Simpan
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

// CreateCourseForm.propTypes = {
//   handleSubmit: PropTypes.func.isRequired,
// };

export default reduxForm({
  form: 'create_course', // a unique identifier for this form
})(CreateCourseForm);
