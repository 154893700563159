/* eslint-disable no-console */
/* eslint-disable no-undef */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { privateRole } from './helpers';

const AdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      privateRole() === 'Admin'
        ? <Component {...props} />
        : <Redirect to="/" />
    )}
  />
);

AdminRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default AdminRoute;
