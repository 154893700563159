/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/react-in-jsx-scope */
import { useMemo } from 'react';
// import { Button } from 'reactstrap';
// import { useHistory } from 'react-router-dom';

// const PhotoFormatter = (value) => (
//   <div className="products-list__img-wrap">
//     <img src={value} alt="" />
//   </div>
// );
// const topicEdit = (history, info) => {
//   history.push({
//     pathname: `/admin/topic-edit/${info.id}`,
//     state: { data: info },
//   });
// };

// const materialDetail = (history, info) => {
//   history.push({
//     pathname: `/admin/course-material/${info.id}`,
//     state: { data: info },
//   });
// };

const ListDataQuizScore = (dataAPI) => {
  // const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: 'No',
        accessor: 'number',
        width: 20,
        disableSortBy: true,
      },
      {
        Header: 'Nama Siswa',
        accessor: 'name',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Nilai',
        accessor: 'score',
        disableSortBy: true,
        width: 110,
      },
    ], [],
  );

  const data = [];
  const rows = () => {
    if (!dataAPI) {
      data.push({
        number: '-',
      });
    } else {
      for (let i = 0; i < dataAPI.length; i += 1) {
        if (dataAPI.length === 0) {
          data.push({
            number: '-',
          });
        } else {
          data.push({
            id: dataAPI[i].id,
            number: `${i + 1}`,
            name: `${dataAPI[i].answer_detail.user_detail.name}`,
            score: `${dataAPI[i].score}`,
          });
        }
      }
    }
  };

  rows();
  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default ListDataQuizScore;
