/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
// import ReactExport from 'react-export-excel';
import {
  Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import classnames from 'classnames';
import TopicListTable from './TopicList';
import ListDataTopic from './ListDataTopic';
import ListDataAttendance from '../../../Attendance/components/listDataAttendance';
import {
  URL_ATTENDANCE_BY_IDCOURSE,
  URL_INSTRUCTOR_BY_IDCOURSE,
  URL_STUDENT_LIST_CUSTOM,
  URL_TOPIC_BY_IDCOURSE,
} from '../../../../../utils/EndPoints';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/Types';
import AttendanceListTable from '../../../Attendance/components/attendanceList';
import StudentListTable from '../../StudentList/components/studentList';
import ListDataStudentList from '../../StudentList/components/listDataStudentList';
import InstructorListTable from '../../Instructor/components/instructorList';
import ListDataInstructorList from '../../Instructor/components/listDataInstructor';

const CourseTopicContent = () => {
  // const { ExcelFile } = ReactExport.ExcelFile;
  // const { ExcelSheet } = ReactExport.ExcelFile.ExcelSheet;
  // const { ExcelColumn } = ReactExport.ExcelFile.ExcelColumn;

  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const { id } = useParams();

  // list
  const [topics, setTopics] = useState([]);
  const [attendance, setAttendance] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [instructor, setInstructor] = useState([]);

  const listDataTable = ListDataTopic(topics);
  const listDataTable1 = ListDataAttendance(attendance);
  const listDataTable2 = ListDataStudentList(studentList);
  const listDataTable3 = ListDataInstructorList(instructor);

  // loading
  const [loadingTopic, setLoadingTopic] = useState(false);
  const [loadingAttendance, setLoadingAttendance] = useState(false);
  const [loadingStudentList, setLoadingStudentList] = useState(false);
  const [loadingInstructorList, setLoadingInstructorList] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };

    // get topic
    setLoadingTopic(true);
    axios.get(`${URL_TOPIC_BY_IDCOURSE}${id}/`, options)
      .then((res) => {
        setTopics(res.data);
        setLoadingTopic(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingTopic(false);
      });

    // get attendance
    setLoadingAttendance(true);
    axios.get(`${URL_ATTENDANCE_BY_IDCOURSE}${id}/date/${moment().format('YYYY-MM-DD')}/`, options)
      .then((res) => {
        setAttendance(res.data);
        setLoadingAttendance(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingAttendance(false);
      });

    // get student list
    setLoadingStudentList(true);
    axios.get(`${URL_STUDENT_LIST_CUSTOM}${id}/`, options)
      .then((res) => {
        setStudentList(res.data);
        setLoadingStudentList(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingStudentList(false);
      });

    // get instructor list
    setLoadingInstructorList(true);
    axios.get(`${URL_INSTRUCTOR_BY_IDCOURSE}${id}`, options)
      .then((res) => {
        setInstructor(res.data);
        setLoadingInstructorList(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingInstructorList(false);
      });
  }, []);

  return (
    <div className="tabs__wrap">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => toggle('1')}
          >
            Topik
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => toggle('2')}
          >
            Kehadiran
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => toggle('3')}
          >
            Daftar Siswa
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '4' })}
            onClick={() => toggle('4')}
          >
            Pengajar
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => toggle('3')}
          >
            Live Session
          </NavLink>
        </NavItem> */}
        {/* <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => toggle('3')}
          >
            Offers
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '4' })}
            onClick={() => toggle('4')}
          >
            Refounds
          </NavLink>
        </NavItem> */}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <TopicListTable
            listDataTable={listDataTable}
            isLoading={loadingTopic}
          />
        </TabPane>
        {/* <ExcelFile>
          <ExcelSheet data={attendance} name="Attendance">
            <ExcelColumn label="Tanggal" value="createdAt" />
            <ExcelColumn label="NIP" value="user_detail.nip" />
            <ExcelColumn label="Nama" value="user_detail.name" />
            <ExcelColumn label="Status" value="status" />
          </ExcelSheet>
        </ExcelFile> */}
        <TabPane tabId="2">
          <form className="form">
            <div className="form__form-group">
              <div className="form__form-group-field col-lg-3" style={{ float: 'left', marginLeft: '20px' }}>
                <input
                  name="date"
                  type="date"
                  placeholder="Masukan Tanggal"
                  onChange={(e) => {
                    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                    const options = {
                      headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${token}`,
                      },
                    };
                    axios.get(`${URL_ATTENDANCE_BY_IDCOURSE}${id}/date/${e.target.value}/`, options)
                      .then((res) => {
                        setAttendance(res.data);
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }}
                />
              </div>
            </div>
          </form>
          <AttendanceListTable
            listDataTable={listDataTable1}
            isLoading={loadingAttendance}
          />
        </TabPane>
        <TabPane tabId="3">
          <StudentListTable
            listDataTable={listDataTable2}
            isLoading={loadingStudentList}
          />
        </TabPane>
        <TabPane tabId="4">
          <InstructorListTable
            listDataTable={listDataTable3}
            isLoading={loadingInstructorList}
          />
        </TabPane>
        {/* <TabPane tabId="3">
          <LiveSessionListTable
            listDataTable={listDataTable2}
            isLoading={loadingSession}
          />
        </TabPane> */}
        {/* <TabPane tabId="3">
          <p>Direction has strangers now believing. Respect enjoyed gay far exposed parlors towards. Enjoyment
            use tolerably dependent listening men. No peculiar in handsome together unlocked do by.
          </p>
        </TabPane>
        <TabPane tabId="4">
          <p>Direction has strangers now believing. Respect enjoyed gay far exposed parlors towards. Enjoyment
            use tolerably dependent listening men. No peculiar in handsome together unlocked do by. Article
            concern joy anxious did picture sir her.
          </p>
        </TabPane> */}
      </TabContent>
    </div>
  );
};

export default CourseTopicContent;
