/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Progress, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import { RiErrorWarningLine } from 'react-icons/ri';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import {
  LOCALSTORAGE_TOKEN,
} from '../../../../../utils/Types';
import renderFileInputField from '../../../../../shared/components/form/FileInput';
import {
  URL_LIBRARY,
} from '../../../../../utils/EndPoints';

const ELibraryEditForm = (stateOri) => {
  const history = useHistory();
  const params = useParams();
  const [progress, setProgress] = useState(null);
  const [isEditable, setEditable] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [alertThumbnail, setAlertThumbnail] = useState(false);
  const [alertFilePDF, setAlertFilePDF] = useState(false);
  const { location } = history;
  const { state } = location;
  const { initialize } = stateOri;
  const { data } = state;
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  useEffect(() => {
    initialize(data);
  }, []);

  const onSubmit = (e) => {
    // setLoading(true);
    e.preventDefault();
    const data = new FormData();
    if (e.target.thumbnail.files[0]) {
      data.append('thumbnail', e.target.thumbnail.files[0]);
    }
    data.append('title', e.target.title.value);
    data.append('category', e.target.category.value);
    data.append('publish_year', e.target.publish_year.value);
    data.append('author', e.target.author.value);
    if (e.target.video_link) {
      data.append('video_link', e.target.video_link.value);
    }
    if (e.target.file_pdf.files[0]) {
      data.append('file_pdf', e.target.file_pdf.files[0]);
    }
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: (data) => {
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    };
    axios
      .patch(`${URL_LIBRARY + params.id}/`, data, options)
      .then(() => {
        history.push('/admin/e-library');
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  };

  const imageHandler = () => {
    if (thumbnail) {
      return thumbnail;
    } if (data.thumbnail) {
      return data.thumbnail;
    }
    return '/img/no_image.png';
  };

  const inputImageHandler = (e) => {
    setThumbnail(URL.createObjectURL(e.file));
    const conditions = ['image/png', 'image/jpg', 'image/jpeg'];

    if (conditions.some((el) => e.file.type.includes(el))) {
      setAlertThumbnail(false);
    } else {
      setAlertThumbnail(true);
    }
  };

  return (
    <Col md={12} lg={12}>
      <ButtonToolbar className="form__button-toolbar">
        <Button color="danger" onClick={history.goBack}>
          Kembali
        </Button>
      </ButtonToolbar>
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <div className="card__title">
            <h5 className="bold-text">Detail E-Library</h5>
          </div>
          <form className="form" onSubmit={onSubmit}>
            <img src={imageHandler()} alt="" className="testimonial-image" />
            <span className="form__form-group-label">Thumbnail (Gambar) dengan ukuran lebar 300 X panjang 400</span>
            <div className="form__form-group">
              <div className="form__form-group-field mb-0-label">
                <Field
                  name="thumbnail"
                  component={renderFileInputField}
                  required
                  onChange={(e) => { inputImageHandler(e); }}
                />
              </div>
              {alertThumbnail && (
                <span className="text-red"><RiErrorWarningLine /> Mohon isi dengan type image (.png, .jpeg, .jpg)</span>
              )}
            </div>
            <span className="form__form-group-label">Judul</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="title"
                  component="input"
                  type="text"
                  placeholder="Judul"
                  required
                  disabled={!isEditable}
                />
              </div>
            </div>

            <span className="form__form-group-label">Kategori</span>
            <div className="form__form-group">
              <div className="form__form-group-field" style={{ display: 'block' }}>
                <Select
                  name="category"
                  defaultValue={{ value: data.category, label: data.category }}
                  options={[
                    { value: 'E-Book', label: 'E-Book' },
                    { value: 'Video', label: 'Video' },
                    { value: 'Makalah', label: 'Makalah' },
                    { value: 'Karya Tulis', label: 'Karya Tulis' },
                  ]}
                  required
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary: '#ff4861',
                      primary25: 'hsla(352, 100%, 64%, 0.25)',
                      primary50: 'hsla(352, 100%, 64%, 0.50)',
                    },
                  })}
                />
              </div>
            </div>

            <span className="form__form-group-label">Tahun Terbit</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="publish_year"
                  component="input"
                  type="number"
                  placeholder="Tahun Terbit"
                  required
                  disabled={!isEditable}
                />
              </div>
            </div>

            <span className="form__form-group-label">Pengarang</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="author"
                  component="input"
                  type="text"
                  placeholder="Pengarang"
                  required
                  disabled={!isEditable}
                />
              </div>
            </div>
            <span className="form__form-group-label">Link Video</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="video_link"
                  component="input"
                  type="text"
                  placeholder="Link Video"
                  required
                  disabled={!isEditable}
                />
              </div>
            </div>
            <span className="form__form-group-label">File PDF</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="file_pdf"
                  component={renderFileInputField}
                  accept="application/pdf"
                  required
                  onChange={(e) => (e.file.type.includes('application/pdf') ? setAlertFilePDF(false) : setAlertFilePDF(true))}
                />
              </div>
              {alertFilePDF && (
                <span style={{ color: 'red' }}><RiErrorWarningLine /> Mohon isi dengan type file pdf</span>
              )}
              <p>Current Link: {data?.file_pdf !== null ? (
                <a href={data.file_pdf} target="_blank" rel="noreferrer">
                  {data.file_pdf}
                </a>
              ) : '-'}
              </p>
            </div>
            {progress !== null && (
              <Card>
                <Col md={12} lg={12}>
                  <Progress animated value={progress}>{progress}%</Progress>
                </Col>
              </Card>
            )}

            <ButtonToolbar className="form__button-toolbar">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setEditable(!isEditable)}
                color={isEditable ? 'danger' : 'primary'}
              >
                {' '}
                {!isEditable ? 'Ubah' : 'Batal'}
              </button>
              {isEditable && (
                <button
                  type="submit"
                  className="btn btn-primary"
                >
                  {' '}
                  Simpan Perubahan
                </button>
              )}
              {isEditable === false && (
                <button
                  type="button"
                  size="sm"
                  onClick={() => toggle()}
                  className="btn btn-danger"
                >
                  Hapus
                </button>
              )}
              <Modal
                toggle={toggle}
                isOpen={modal}
                backdrop={false}
              >
                <ModalHeader toggle={toggle}>
                  Hapus E-Library
                </ModalHeader>
                <ModalBody>
                  Apakah kamu yakin ingin menghapus E-Library ini?
                </ModalBody>
                <ModalFooter>
                  <Button
                    onClick={() => {
                      const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                      const options = {
                        headers: {
                          'Content-Type':
                            'application/json',
                          Authorization: `Token ${token}`,
                        },
                      };
                      axios
                        .delete(
                          `${URL_LIBRARY}${data.id}`,
                          options,
                        )
                        .then(() => {
                          history.push('/admin/e-library/');
                        })
                        .catch(() => {
                          console.log('error');
                        });
                    }}
                  >
                    Hapus
                  </Button>
                </ModalFooter>
              </Modal>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

// CreateCourseForm.propTypes = {
//   handleSubmit: PropTypes.func.isRequired,
// };

export default reduxForm({
  form: 'EditELibrary_form', // a unique identifier for this form
})(ELibraryEditForm);
