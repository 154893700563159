/* eslint-disable no-console */
import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import FacebookIcon from 'mdi-react/FacebookIcon';
// import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { LOCALSTORAGE_TOKEN, LOCALSTORAGE_USERDETAIL } from '../../../../utils/Types';
import { AUTH_URL } from '../../../../utils/EndPoints';
import NewPasswordForm from './components/newPasswordForm';

const NewPassword = () => {
  const [isVisibleAlert, setVisibleAlert] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const errorHandling = () => {
    setLoading(false);
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 5000);
  };
  const onSubmit = (e) => {
    const data = new FormData();
    data.append('email', e.email);
    data.append('password', e.password);
    setLoading(true);
    axios.post(`${AUTH_URL}token/login`, data)
      .then((res) => {
        // authentication
        const token = res.data.auth_token;

        localStorage.setItem(LOCALSTORAGE_TOKEN, token);
        // authorization
        axios.get(`${AUTH_URL}users/me`, { headers: { Authorization: `Token ${token}` } })
          .then((userData) => {
            const userdetail = userData.data;
            if (userdetail.role === 'Admin') {
              localStorage.setItem(LOCALSTORAGE_USERDETAIL, JSON.stringify(userData.data));
              history.push('/admin/dashboard');
            } else {
              errorHandling('Anda tidak memiliki hak untuk mengakses Educourse Report Management');
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  if (isLoading) {
    return (
      <div className="load">
        <div className="load__icon-wrap">
          <svg className="load__icon">
            <path fill="red" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
          </svg>
        </div>
      </div>
    );
  }
  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          {/* <div className="account__head">
            <h3 className="account__title">Welcome to
              <span className="account__logo"> Easy
                <span className="account__logo-accent">DEV</span>
              </span>
            </h3>
            <h4 className="account__subhead subhead">Start your business easily</h4>
          </div> */}
          <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Masukan Password Baru</h2>
          <div className="login-form">
            {isVisibleAlert ? (
              <Alert visible={isVisibleAlert} color="danger">
                <p><span className="bold-text">Login Failed</span></p>
              </Alert>
            ) : ''}
          </div>
          <NewPasswordForm onSubmit={(e) => onSubmit(e)} />
        </div>
      </div>
    </div>
  );
};

export default NewPassword;

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
