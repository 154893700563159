/* eslint-disable no-shadow */
/* eslint-disable no-console */
import React from 'react';
import {
  Container,
} from 'reactstrap';
import TestimonialForm from './components/TestimonialForm';

const CreateTestimonial = () => (
  <Container className="dashboard">
    <TestimonialForm />
  </Container>
);
export default CreateTestimonial;
