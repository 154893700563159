/* eslint-disable no-console */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import axios from 'axios';
import Panel from '../../../../shared/components/Panel';
import getTooltipStyles from '../../../../shared/helpers';
import { LOCALSTORAGE_TOKEN } from '../../../../utils/Types';
import { URL_CERTIFICATEPERMOUNTH } from '../../../../utils/EndPoints';

const Chart = ({ themeName }) => {
  const [historyLogin, setHistoryLogin] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios.get(URL_CERTIFICATEPERMOUNTH, options)
      .then((res) => {
        setHistoryLogin(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Panel md={12} lg={12} xl={12} title="Lulusan Per Bulan">
      <div dir="ltr">
        <form className="form">
          <div className="form__form-group">
            <div className="form__form-group-field col-lg-3" style={{ float: 'right' }}>
              <input
                name="date"
                type="month"
                placeholder="Masukan Tanggal"
                onChange={(e) => {
                  const year = e.target.value.slice(0, 4);
                  const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                  console.log(token);
                  console.log(year);
                  const options = {
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Token ${token}`,
                    },
                  };
                  // console.log(`${URL_CERTIFICATEPERMOUNTH}?year=${year}`);
                  axios.get(`${URL_CERTIFICATEPERMOUNTH}?year=${year}`, options)
                    .then((res) => {
                      setHistoryLogin(res.data);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              />
            </div>
          </div>
        </form>
        <ResponsiveContainer height={250} className="dashboard__area">
          <AreaChart data={historyLogin !== null && historyLogin} margin={{ top: 20, left: -15, bottom: 20 }}>
            <XAxis dataKey="month" tickLine={false} />
            <YAxis tickLine={false} orientation="right" />
            <Tooltip {...getTooltipStyles(themeName, 'defaultItems')} />
            <Legend />
            <CartesianGrid />
            <Area name="Bulan" type="monotone" dataKey="total_certificate" fill="red" stroke="red" fillOpacity={0.2} />
            {/* <Area name="Site B" type="monotone" dataKey="b" fill="#70bbfd" stroke="#70bbfd" fillOpacity={0.2} /> */}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </Panel>
  );
};
Chart.propTypes = {
  themeName: PropTypes.string.isRequired,
};

export default connect((state) => ({ themeName: state.theme.className }))(Chart);
