/* eslint-disable no-shadow */
/* eslint-disable no-console */
import React from 'react';
import {
  Container,
} from 'reactstrap';
import ELibraryForm from './components/ELibraryForm';

const CreateELibrary = () => (
  <Container className="dashboard">
    <ELibraryForm />
  </Container>
);
export default CreateELibrary;
