/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/react-in-jsx-scope */
import { useMemo } from 'react';
import { Button } from 'reactstrap';
// import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

const IntructorEdit = (history, info) => {
  history.push({
    pathname: `/admin/instructor-edit/${info.id}`,
    state: { data: info },
  });
};

const ListDataInstructorList = (dataAPI) => {
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: 'No',
        accessor: 'number',
        width: 20,
        disableSortBy: true,
      },
      {
        Header: 'Nama Guru',
        accessor: 'name',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Posisi',
        accessor: 'position',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Aksi',
        accessor: 'action',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
    ], [],
  );

  const data = [];
  const rows = () => {
    if (!dataAPI) {
      data.push({
        number: '-',
      });
    } else {
      for (let i = 0; i < dataAPI.length; i += 1) {
        if (dataAPI.length === 0) {
          data.push({
            number: '-',
          });
        } else {
          data.push({
            id: dataAPI[i].id,
            number: `${i + 1}`,
            name: dataAPI[i].user_detail ? dataAPI[i].user_detail.name : '-',
            position: dataAPI[i].position,
            action: [
              // eslint-disable-next-line no-loop-func
              // eslint-disable-next-line max-len
              (<Button color="outline-success" onClick={() => IntructorEdit(history, dataAPI[i])}>Detail</Button>),
            ],
          });
        }
      }
    }
  };

  rows();
  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default ListDataInstructorList;
