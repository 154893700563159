/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/react-in-jsx-scope */
import
React,
{ useMemo } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

const eLibraryDetail = (history, info) => {
  history.push({
    pathname: `/admin/e-library-detail/${info.id}`,
    state: { data: info },
  });
};

const ListDataELibrary = (dataAPI) => {
  const links = (index, category) => {
    if (category === 'Video') {
      return dataAPI[index].video_link;
    } if (category === 'E-Book' || category === 'Makalah' || category === 'Karya Tulis') {
      return dataAPI[index].file_pdf;
    }

    return null;
  };
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: 'No',
        accessor: 'number',
        width: 20,
        disableSortBy: true,
      },
      {
        Header: 'Judul',
        accessor: 'title',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Kategori',
        accessor: 'category',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Pengarang',
        accessor: 'author',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Link',
        accessor: 'link',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
    ], [],
  );

  const data = [];
  const rows = () => {
    if (!dataAPI) {
      data.push({
        number: '-',
      });
    } else {
      for (let i = 0; i < dataAPI.length; i += 1) {
        data.push({
          id: dataAPI[i].id,
          number: `${i + 1}`,
          title: dataAPI[i].title,
          category: dataAPI[i].category,
          author: dataAPI[i].author,
          link: <a href={links(i, dataAPI[i].category)} target="_blank" rel="noreferrer">link {dataAPI[i].category}</a>,
          status: [
            // eslint-disable-next-line no-loop-func
            // eslint-disable-next-line max-len
            (<Button key={dataAPI[i].id} color="outline-success" onClick={() => eLibraryDetail(history, dataAPI[i])}>Detail</Button>),
          ],
        });
      }
    }
  };

  rows();
  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default ListDataELibrary;
