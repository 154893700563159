/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/react-in-jsx-scope */
import
React,
{ useMemo } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

const teacherDetail = (history, info) => {
  history.push({
    pathname: `/admin/teacher-detail/${info.id}`,
    state: { data: info },
  });
};

const ListDataTeacher = (dataAPI) => {
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: 'No',
        accessor: 'number',
        width: 20,
      },
      {
        Header: 'Email',
        accessor: 'email',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Nama',
        accessor: 'name',
        width: 110,
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 110,
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ], [],
  );

  const data = [];
  const rows = () => {
    for (let i = 0; i < dataAPI.length; i += 1) {
      data.push({
        id: dataAPI[i].id,
        number: `${i + 1}`,
        email: dataAPI[i].email,
        name: dataAPI[i].name,
        status: [
          // eslint-disable-next-line no-loop-func
          // eslint-disable-next-line max-len
          (<Button key={dataAPI[i].id} color="outline-success" onClick={() => teacherDetail(history, dataAPI[i])}>Detail</Button>),
        ],
      });
    }
  };

  rows();
  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default ListDataTeacher;
