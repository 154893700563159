/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactExport from 'react-export-excel';
import {
  Container, Row, ButtonToolbar, Button,
} from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/Types';
import { URL_SCORE_BY_IDQUIZ } from '../../../../../utils/EndPoints';
import QuizScoreListTable from './components/quizScoreList';
import ListDataQuizScore from './components/listDataQuizScore';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

const QuizScore = () => {
  const [score, setScore] = useState([]);
  const listDataTable = ListDataQuizScore(score);
  const [isLoading, setLoading] = useState(false);
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios.get(`${URL_SCORE_BY_IDQUIZ}${id}`, options)
      .then((res) => {
        setScore(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const dataExcel = score !== null ? (score.map((item) => ({
    name: item.answer_detail.user_detail.name,
    score: item.score,
  }))) : [];

  return (
    <Container className="dashboard">
      <ButtonToolbar className="form__button-toolbar">
        <Button color="primary" onClick={history.goBack}>
          Kembali
        </Button>
      </ButtonToolbar>
      {dataExcel.length > 0 && (
        <ExcelFile
          filename={`Nilai Kuis ${score[0].answer_detail.quiz_detail.name}`}
          element={(<Button>Download Nilai Kuis</Button>)}
        >
          <ExcelSheet data={dataExcel} name="Nilai Siswa">
            <ExcelColumn label="Name" value="name" />
            <ExcelColumn label="Nilai" value="score" />
          </ExcelSheet>
        </ExcelFile>
      )}
      <Row>
        <QuizScoreListTable
          listDataTable={listDataTable}
          isLoading={isLoading}
        />
      </Row>
    </Container>
  );
};
export default QuizScore;
