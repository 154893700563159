/* eslint-disable no-alert */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card, CardBody, Col, Modal, ModalBody, ModalFooter, ModalHeader, Spinner,
} from 'reactstrap';
import ReactExport from 'react-export-excel';
import axios from 'axios';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import ReactTableBase from '../../../../shared/components/table/ReactTableBase';
import { URL_ATTENDANCE_BY_IDCOURSE } from '../../../../utils/EndPoints';
import { LOCALSTORAGE_TOKEN } from '../../../../utils/Types';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

const AttendanceListTable = ({ listDataTable, isLoading }) => {
  const tableConfig = {
    isEditable: false,
    isSortable: true,
    isResizable: false,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
  };

  const params = useParams();

  const [attendance, setAttendance] = useState([]);
  const [attendanceByRange, setAttendanceByRange] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [hide, setHide] = useState(false);
  // const [attendanceRangeDate, setAttendanceRangeDate] = useState([]);

  const [confirm, setConfirm] = useState(0);
  const [modal, setModal] = useState(false);
  const toggle = (id) => {
    setConfirm(id);
    setModal(!modal);
  };

  useEffect(() => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios.get(`${URL_ATTENDANCE_BY_IDCOURSE}${params.id}/date/${moment().format('YYYY-MM-DD')}/`, options)
      .then((res) => {
        setAttendance(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const dataExcel = attendance !== null ? (attendance.map((item) => ({
    name: item.name,
    inHour: item.inHour,
    outHour: item.outHour,
    rangeStudy: item.rangeStudy,
  }))) : [];

  const dataExcel1 = attendanceByRange !== null ? (attendanceByRange.map((item) => (
    item.attendances.map((item1) => ({
      name: item1.name,
      inHour: item1.inHour,
      outHour: item1.outHour,
      rangeStudy: item1.rangeStudy,
    }))
  ))) : [];

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="dashboard__booking-card">
          {/* <Collapse title="Buat Kehadiran" className="with-shadow">
            <CreateTopicForm
              handleSubmit={(e) => onSubmit(e)}
            />
          </Collapse> */}
          {/* <Col md={12} lg={12}>
            <Progress animated value={progress} />
          </Col> */}
          <div className="card__title">
            <h5 className="bold-text">List Kehadiran</h5>
            {/* <input
              name="date"
              type="date"
              placeholder="Masukan Tanggal"
              onChange={(e) => {
                const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                const options = {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${token}`,
                  },
                };
                axios.get(`${URL_ATTENDANCE_BY_IDCOURSE}${params.id}/date/${e.target.value}/`, options)
                  .then((res) => {
                    setAttendance(res.data);
                    console.log(res.data);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            /> */}
          </div>
          {dataExcel.length > 0 && (
            <ExcelFile
              filename="Absensi Peserta"
              element={(<Button>Download Absen</Button>)}
            >
              <ExcelSheet data={dataExcel} name="Nilai Siswa">
                <ExcelColumn label="Name" value="name" />
                <ExcelColumn label="Jam Masuk" value="inHour" />
                <ExcelColumn label="Jam Keluar" value="outHour" />
                <ExcelColumn label="Waktu Belajar" value="rangeStudy" />
              </ExcelSheet>
            </ExcelFile>
          )};
          <button
            type="button"
            size="sm"
            style={{ float: 'right' }}
            onClick={() => toggle(params.id)}
            className="btn btn-danger"
          >
            Download Absensi Berdasarkan Tanggal
          </button>
          <Modal
            toggle={toggle}
            isOpen={modal}
            backdrop={false}
          >
            <ModalHeader toggle={toggle}>
              Download Absen Berdasarkan Tanggal
            </ModalHeader>
            <ModalBody>
              <form className="form">
                <div className="form__form-group">
                  <div className="form__form-group-field col-lg-12 col-md-12">
                    <input
                      style={{ marginRight: '5px' }}
                      type="date"
                      name="startdate"
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                    <input
                      type="date"
                      name="enddate"
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                </div>
              </form>
            </ModalBody>
            <ModalFooter style={{ display: 'flex', flexWrap: 'nowrap', margin: '0px' }}>
              {!hide && (
                <Button
                  color="danger"
                  style={{ float: 'left', fontSize: 'inherit', marginTop: '0px' }}
                  onClick={() => {
                    setHide(true);
                    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                    const options = {
                      headers: {
                        'Content-Type':
                          'application/json',
                        Authorization: `Token ${token}`,
                      },
                    };
                    axios
                      .get(
                        `${URL_ATTENDANCE_BY_IDCOURSE}${confirm}/startdate/${startDate}/enddate/${endDate}/`,
                        options,
                      )
                      .then((res) => {
                        setAttendanceByRange(res.data);
                        // alert('Data Siap di Download');
                      })
                      .catch(() => {
                        console.log('error');
                      });
                  }}
                >
                  Ambil Data
                </Button>
              )}
              {hide && (
                <ExcelFile
                  filename="Absensi Peserta Berdasarkan Range Tanggal"
                  element={(<Button color="danger" style={{ float: 'right', fontSize: 'inherit' }}>Download Absen</Button>)}
                >
                  {attendanceByRange.map((item, i) => (
                    <ExcelSheet data={dataExcel1[i]} name={`Tanggal ${item.date}`}>
                      <ExcelColumn label="Name" value="name" />
                      <ExcelColumn label="Jam Masuk" value="inHour" />
                      <ExcelColumn label="Jam Keluar" value="outHour" />
                      <ExcelColumn label="Waktu Belajar" value="rangeStudy" />
                    </ExcelSheet>
                  ))}
                </ExcelFile>
              )}
            </ModalFooter>
          </Modal>
          {isLoading ? <Spinner /> : (
            <ReactTableBase
              columns={listDataTable.tableHeaderData}
              data={listDataTable.tableRowsData}
              tableConfig={tableConfig}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

AttendanceListTable.propTypes = {
  listDataTable: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default AttendanceListTable;
