/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Modal, ModalHeader, ModalBody, ModalFooter, Progress,
} from 'reactstrap';
import renderFileInputField from '../../../../../../shared/components/form/FileInput';
import { URL_ASSIGNMENT } from '../../../../../../utils/EndPoints';
import { LOCALSTORAGE_TOKEN } from '../../../../../../utils/Types';

const EditAssignmentForm = (stateOri) => {
  const history = useHistory();
  const { id } = useParams();
  const { location } = history;
  const { state } = location;
  const { data } = state;
  const { initialize } = stateOri;

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState();

  const [isEditable, setEditable] = useState(false);
  const [topic, setTopic] = useState([]);
  const [confirm, setConfirm] = useState(0);
  const [modal, setModal] = useState(false);
  const toggle = (idTopic) => {
    setConfirm(idTopic);
    setModal(!modal);
  };
  useEffect(() => {
    initialize(data);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };

    axios
      .get(`${URL_ASSIGNMENT}${id}/`, options)
      .then((res) => {
        setTopic(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const dataEdit = new FormData();
    dataEdit.append('title', e.target.title.value);
    if (e.target.file.files[0]) {
      dataEdit.append('file', e.target.file.files[0]);
    }
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: (data) => {
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    };
    axios.patch(`${URL_ASSIGNMENT}${id}/`, dataEdit, options)
      .then(() => {
        history.push(`/admin/course-material/${data.topicId}`);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response.data);
        setLoading(false);
      });
  };
  return (
    <Col md={12} lg={12}>
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <form className="form" onSubmit={onSubmit}>
            <span className="form__form-group-label">Judul Tugas</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="title"
                  component="input"
                  type="text"
                  placeholder="Judul Tugas"
                  disabled={!isEditable}
                />
              </div>
            </div>
            <span className="form__form-group-label">Upload File</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="file"
                  component={renderFileInputField}
                  required
                />
              </div>
              <p>Current Link: {data !== null ? (
                <a href={data.file} target="_blank" rel="noreferrer">
                  {data.file}
                </a>
              ) : '-'}
              </p>
            </div>
            {loading && (
              <Card>
                <Col md={12} lg={12}>
                  <Progress animated value={progress}>{progress}%</Progress>
                </Col>
              </Card>
            )}
            <ButtonToolbar className="form__button-toolbar">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setEditable(!isEditable)}
                color={isEditable && 'danger'}
              >
                {' '}
                {!isEditable ? 'Ubah' : 'Batal'}
              </button>
              {isEditable && (
                <button
                  type="submit"
                  className="btn btn-primary"
                >
                  {' '}
                  Simpan Perubahan
                </button>
              )}
              {isEditable === false && (
                <button
                  type="button"
                  size="sm"
                  onClick={() => toggle(topic.id)}
                  className="btn btn-danger"
                >
                  Hapus
                </button>
              )}
              <Modal
                toggle={toggle}
                isOpen={modal}
                backdrop={false}
              >
                <ModalHeader toggle={toggle}>
                  Hapus Tuga
                </ModalHeader>
                <ModalBody>
                  Apakah kamu yakin ingin menghapus tugas ini?
                </ModalBody>
                <ModalFooter>
                  <Button
                    onClick={() => {
                      const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                      const options = {
                        headers: {
                          'Content-Type':
                            'application/json',
                          Authorization: `Token ${token}`,
                        },
                      };
                      axios
                        .delete(
                          `${URL_ASSIGNMENT}${confirm}/`,
                          options,
                        )
                        .then(() => {
                          history.push(`/admin/course-material/${data.topicId}`);
                          // window.location.reload();
                          // history.push("/dashboard/teacher/listing");
                        })
                        .catch(() => {
                          console.log('error');
                        });
                    }}
                  >
                    Hapus
                  </Button>
                </ModalFooter>
              </Modal>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

// EditTopicForm.propTypes = {
//   handleSubmit: PropTypes.func.isRequired,
// };

export default reduxForm({
  form: 'edit_assignment', // a unique identifier for this form
  enableReinitialize: true,
})(EditAssignmentForm);
