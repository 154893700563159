/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Modal, ModalHeader, ModalBody, ModalFooter, Progress,
} from 'reactstrap';
import Select from 'react-select';
import { URL_INSTRUCTOR, URL_USERBYROLE } from '../../../../../../utils/EndPoints';
import { LOCALSTORAGE_TOKEN } from '../../../../../../utils/Types';

const EditInstructorForm = (stateOri) => {
  const history = useHistory();
  const { id } = useParams();
  const { location } = history;
  const { state } = location;
  const { data } = state;
  const { initialize } = stateOri;

  const [initId, setInitId] = useState(null);

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState();

  const [isEditable, setEditable] = useState(false);
  const [instructors, setInstructors] = useState([]);
  const [confirm, setConfirm] = useState(0);
  const [modal, setModal] = useState(false);
  const toggle = (idInstructor) => {
    setConfirm(idInstructor);
    setModal(!modal);
  };

  useEffect(() => {
    initialize(data);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };

    axios
      .get(`${URL_INSTRUCTOR}${id}/`, options)
      .then((res) => {
        if (res.data.user_detail) {
          setInitId(res.data.user_detail.id);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_USERBYROLE}Teacher/`, options)
      .then((res) => {
        setInstructors(
          res.data.map((item) => ({
            value: item.id,
            label: `${item.name}`,
          })),
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // index selected instructor id
  const indexInstructor = instructors.map((object) => object.value).indexOf(initId);

  const onSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const dataEdit = new FormData();
    dataEdit.append('userId', e.target.teacherId.value);
    dataEdit.append('position', e.target.position.value);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: () => {
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    };
    axios.patch(`${URL_INSTRUCTOR}${id}/`, dataEdit, options)
      .then((res) => {
        history.push(`/admin/course-topic/${res.data.courseId}`);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response.data);
        setLoading(false);
      });
  };
  return (
    <Col md={12} lg={12}>
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <form className="form" onSubmit={onSubmit}>
            <span className="form__form-group-label">Guru</span>
            <div className="form__form-group">
              <div>
                {indexInstructor >= 0 && (
                  <Select
                    name="teacherId"
                    options={instructors}
                    defaultValue={instructors[indexInstructor]}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary: '#ff4861',
                        primary25: 'hsla(352, 100%, 64%, 0.25)',
                        primary50: 'hsla(352, 100%, 64%, 0.50)',
                      },
                    })}
                  />
                )}
                {indexInstructor < 0 && (
                <Select
                  name="teacherId"
                  options={instructors}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary: '#ff4861',
                      primary25: 'hsla(352, 100%, 64%, 0.25)',
                      primary50: 'hsla(352, 100%, 64%, 0.50)',
                    },
                  })}
                />
                )}
              </div>
            </div>
            <span className="form__form-group-label">Posisi</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="position"
                  disabled={!isEditable}
                  component="input"
                  type="text"
                  required
                />
              </div>
            </div>

            {loading && (
              <Card>
                <Col md={12} lg={12}>
                  <Progress animated value={progress}>{progress}%</Progress>
                </Col>
              </Card>
            )}

            <ButtonToolbar className="form__button-toolbar">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setEditable(!isEditable)}
                color={isEditable && 'danger'}
              >
                {' '}
                {!isEditable ? 'Ubah' : 'Batal'}
              </button>
              {isEditable && (
              <button
                type="submit"
                className="btn btn-primary"
              >
                {' '}
                Simpan Perubahan
              </button>
              )}
              {isEditable === false && (
              <button
                type="button"
                size="sm"
                onClick={() => toggle(data.id)}
                className="btn btn-danger"
              >
                Hapus
              </button>
              )}
              <Modal
                toggle={toggle}
                isOpen={modal}
                backdrop={false}
              >
                <ModalHeader toggle={toggle}>
                  Hapus Pengajar
                </ModalHeader>
                <ModalBody>
                  Apakah kamu yakin ingin menghapus pengajar ini dari kelas?
                </ModalBody>
                <ModalFooter>
                  <Button
                    onClick={() => {
                      const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                      const options = {
                        headers: {
                          'Content-Type':
                            'application/json',
                          Authorization: `Token ${token}`,
                        },
                      };
                      axios
                        .delete(
                          `${URL_INSTRUCTOR}${confirm}`,
                          options,
                        )
                        .then(() => {
                          history.push(`/admin/course-topic/${data.courseId}`);
                          // window.location.reload();
                          // history.push("/dashboard/teacher/listing");
                        })
                        .catch(() => {
                          console.log('error');
                        });
                    }}
                  >
                    Hapus
                  </Button>
                </ModalFooter>
              </Modal>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

// EditInstructorForm.propTypes = {
//   handleSubmit: PropTypes.func.isRequired,
// };

export default reduxForm({
  form: 'edit_instructor', // a unique identifier for this form
  enableReinitialize: true,
})(EditInstructorForm);
