/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { RiErrorWarningLine } from 'react-icons/ri';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Progress,
} from 'reactstrap';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import {
  LOCALSTORAGE_TOKEN,
} from '../../../../../utils/Types';
import renderFileInputField from '../../../../../shared/components/form/FileInput';
import {
  URL_TESTIMONIAL,
} from '../../../../../utils/EndPoints';

const TestimonialForm = () => {
  const history = useHistory();
  const [progress, setProgress] = useState(null);
  const [file, setFile] = useState(null);
  const [alertPhoto, setAlertPhoto] = useState(false);

  const onSubmit = (e) => {
    // setLoading(true);
    e.preventDefault();
    const data = new FormData();
    data.append('name', e.target.name.value);
    data.append('position', e.target.position.value);
    data.append('description', e.target.description.value);
    if (e.target.photo.files[0]) {
      data.append('photo', e.target.photo.files[0]);
    }
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: (data) => {
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    };
    axios
      .post(URL_TESTIMONIAL, data, options)
      .then(() => {
        history.push('/admin/testimonial');
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  };

  const imageHandler = () => {
    if (file) {
      return file;
    }
    return '/img/no_image.png';
  };

  const inputImageHandler = (e) => {
    setFile(URL.createObjectURL(e.file));
    const conditions = ['image/png', 'image/jpg', 'image/jpeg'];

    if (conditions.some((el) => e.file.type.includes(el))) {
      setAlertPhoto(false);
    } else {
      setAlertPhoto(true);
    }
  };

  return (
    <Col md={12} lg={12}>
      <ButtonToolbar className="form__button-toolbar">
        <Button color="danger" onClick={history.goBack}>
          Kembali
        </Button>
      </ButtonToolbar>
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <div className="card__title">
            <h5 className="bold-text">Buat Testimoni Baru</h5>
          </div>
          <form className="form" onSubmit={onSubmit}>
            <img src={imageHandler()} alt="" className="testimonial-image" />
            <span className="form__form-group-label">Foto</span>
            <div className="form__form-group">
              <div className="form__form-group-field mb-0-label">
                <Field
                  name="photo"
                  component={renderFileInputField}
                  required
                  onChange={(e) => { inputImageHandler(e); }}
                />
              </div>
              {alertPhoto && (
                <span style={{ color: 'red' }}><RiErrorWarningLine /> Mohon isi dengan type image (.png, .jpeg, .jpg)</span>
              )}
            </div>

            <span className="form__form-group-label">Nama</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="name"
                  component="input"
                  type="text"
                  placeholder="Nama"
                  required
                />
              </div>
            </div>

            <span className="form__form-group-label">Posisi</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="position"
                  component="input"
                  type="text"
                  placeholder="Posisi"
                  required
                />
              </div>
            </div>

            <span className="form__form-group-label">Deskripsi</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="description"
                  component="textarea"
                  type="text"
                  placeholder="Deskripsi"
                  required
                />
              </div>
            </div>

            {progress !== null && (
              <Card>
                <Col md={12} lg={12}>
                  <Progress animated value={progress}>{progress}%</Progress>
                </Col>
              </Card>
            )}

            <ButtonToolbar className="form__button-toolbar">
              <Button
                color="primary"
                type="submit"
              // disabled={!selectedStudent}
              >
                Submit
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

// CreateCourseForm.propTypes = {
//   handleSubmit: PropTypes.func.isRequired,
// };

export default reduxForm({
  form: 'CreateELibrary_form', // a unique identifier for this form
})(TestimonialForm);
