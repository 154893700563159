/* eslint-disable no-shadow */
/* eslint-disable no-console */
import React from 'react';
import {
  Container,
} from 'reactstrap';
import VideoContentEditForm from './components/VideoContentEditForm';

const EditVideoContent = () => (
  <Container className="dashboard">
    <VideoContentEditForm />
  </Container>
);
export default EditVideoContent;
