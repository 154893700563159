/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Modal, ModalHeader, ModalBody, ModalFooter, Progress, Alert, Spinner,
} from 'reactstrap';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { URL_DATAASN, URL_USERS } from '../../../../../utils/EndPoints';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/Types';

const EditUserForm = (stateOri) => {
  const params = useParams();
  const [progress, setProgress] = useState(null);
  const [isEditable, setEditable] = useState(false);
  const [nip, setNip] = useState([]);
  const history = useHistory();
  const { location } = history;
  const { state } = location;
  const { initialize } = stateOri;
  const { data } = state;
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };
  const roleName = {
    Student: 'Murid',
    Teacher: 'Guru',
  };

  // Tinymce Editor
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isVisibleAlert, setVisibleAlert] = useState(false);

  const errorHandling = () => {
    setLoading(false);
    setVisibleAlert(true);
    setTimeout(() => {
      setVisibleAlert(false);
    }, 5000);
  };

  useEffect(() => {
    initialize(data);
    setLoading(true);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios.get(`${URL_DATAASN}`, options)
      .then((res) => {
        setNip(
          res.data.map((e) => ({
            value: e.id,
            label: `${e.nip_baru} | ${e.nama}`,
          })),
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const onSubmit = (e) => {
    // setLoading(true);
    e.preventDefault();
    const data = new FormData();
    data.append('name', e.target.name.value);
    data.append('email', e.target.email.value);
    if (e.target.role.value) {
      data.append('role', e.target.role.value);
    }
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: (data) => {
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    };
    setLoading(true);
    axios
      .patch(`${URL_USERS + params.id}/`, data, options)
      .then(() => {
        history.push('/admin/user');
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setErrorMsg(err.response.data.email);
        errorHandling(err.response.data);
        setLoading(false);
      });
  };

  return (
    <Col md={12} lg={12}>
      <ButtonToolbar className="form__button-toolbar">
        <Button color="danger" onClick={history.goBack}>
          Kembali
        </Button>
      </ButtonToolbar>
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <div className="card__title">
            <h5 className="bold-text">Detail User</h5>
          </div>
          <form className="form" onSubmit={onSubmit}>
            <span className="form__form-group-label">Nama Lengkap</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="name"
                  component="input"
                  type="text"
                  placeholder="Tulis nama lengkap"
                  disabled={!isEditable}
                />
              </div>
            </div>
            <span className="form__form-group-label">Email</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="email"
                  component="input"
                  type="text"
                  placeholder="Tulis Email"
                  disabled={!isEditable}
                />
              </div>
            </div>
            {loading && <Spinner />}
            <span className="form__form-group-label">NIP</span>
            {loading ? (
              <div className="form__form-group">
                <div className="form__form-group-field" style={{ display: 'block' }}>
                  <Select
                    name="nip"
                    defaultValue={{ value: data.nip, label: nip[data.nip] }}
                    options={nip}
                    isClearable="false"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary: '#ff4861',
                        primary25: 'hsla(352, 100%, 64%, 0.25)',
                        primary50: 'hsla(352, 100%, 64%, 0.50)',
                      },
                    })}
                  />
                </div>
              </div>
            ) : (
              <div className="form__form-group">
                <div className="form__form-group-field" style={{ display: 'block' }}>
                  <Select
                    name="nip"
                    options={nip}
                    isClearable="false"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary: '#ff4861',
                        primary25: 'hsla(352, 100%, 64%, 0.25)',
                        primary50: 'hsla(352, 100%, 64%, 0.50)',
                      },
                    })}
                  />
                </div>
              </div>
            )}
            <span className="form__form-group-label">Role</span>
            <div className="form__form-group">
              <div className="form__form-group-field" style={{ display: 'block' }}>
                <Select
                  name="role"
                  defaultValue={{ value: data.role, label: roleName[data.role] }}
                  options={[
                    { label: 'Murid', value: 'Student' },
                    { label: 'Guru', value: 'Teacher' },
                  ]}
                  required
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary: '#ff4861',
                      primary25: 'hsla(352, 100%, 64%, 0.25)',
                      primary50: 'hsla(352, 100%, 64%, 0.50)',
                    },
                  })}
                />
              </div>
            </div>
            {isVisibleAlert ? (
              <Alert visible={isVisibleAlert} color="danger">
                <p><span className="bold-text">{errorMsg}</span></p>
              </Alert>
            ) : ''}
            {loading && (
            <Col md={12} lg={12}>
              <Progress animated value={progress}>{progress}%</Progress>
            </Col>
            )}
            <ButtonToolbar className="form__button-toolbar">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setEditable(!isEditable)}
                color={isEditable ? 'danger' : 'primary'}
              >
                {' '}
                {!isEditable ? 'Ubah' : 'Batal'}
              </button>
              {isEditable && (
                <button
                  type="submit"
                  className="btn btn-primary"
                >
                  {' '}
                  Simpan Perubahan
                </button>
              )}
              {isEditable === false && (
                <button
                  type="button"
                  size="sm"
                  onClick={() => toggle()}
                  className="btn btn-danger"
                >
                  Hapus
                </button>
              )}
              <Modal
                toggle={toggle}
                isOpen={modal}
                backdrop={false}
              >
                <ModalHeader toggle={toggle}>
                  Hapus User
                </ModalHeader>
                <ModalBody>
                  Apakah kamu yakin ingin menghapus User ini?
                </ModalBody>
                <ModalFooter>
                  <Button
                    onClick={() => {
                      const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                      const options = {
                        headers: {
                          'Content-Type':
                            'application/json',
                          Authorization: `Token ${token}`,
                        },
                      };
                      axios
                        .delete(
                          `${URL_USERS}${data.id}/`,
                          options,
                        )
                        .then(() => {
                          history.push('/admin/user');
                        })
                        .catch(() => {
                          console.log('error');
                        });
                    }}
                  >
                    Hapus
                  </Button>
                </ModalFooter>
              </Modal>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

// EditUserForm.propTypes = {
//   handleSubmit: PropTypes.func.isRequired,
// };

export default reduxForm({
  form: 'teacher_edit', // a unique identifier for this form
})(EditUserForm);
