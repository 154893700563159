/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Progress, Button,
} from 'reactstrap';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { LOCALSTORAGE_TOKEN } from '../../../../../../../utils/Types';
// import renderFileInputField from '../../../../../../../shared/components/form/FileInput';
import { URL_SUBMISSION } from '../../../../../../../utils/EndPoints';

const EditSubmissionForm = (stateOri) => {
  const history = useHistory();
  const params = useParams();
  const [progress, setProgress] = useState();

  // loading
  const [loading, setLoading] = useState(false);

  const { location } = history;
  const { state } = location;
  const { data } = state;
  const { initialize } = stateOri;

  useEffect(() => {
    initialize(data);
  }, []);

  return (
    <Col md={12} lg={12}>
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <div className="card__title">
            <h5 className="bold-text">Detail Submission {data.user_detail && data.user_detail.name}</h5>
          </div>
          <form
            className="form"
            onSubmit={(e) => {
              e.preventDefault();
              setLoading(true);
              const data = new FormData();
              data.append('score', e.target.score.value);
              const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
              const options = {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${token}`,
                },
                onUploadProgress: (data) => {
                  setProgress(Math.round((100 * data.loaded) / data.total));
                },
              };
              axios
                .patch(`${URL_SUBMISSION + params.id}/`, data, options)
                .then((res) => {
                  history.push(`/admin/assignment-submission/${res.data.assignmentId}`);
                  setLoading(false);
                })
                .catch((err) => {
                  console.log(err);
                  setLoading(false);
                });
            }}
          >
            <span className="form__form-group-label">Nama</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <input type="text" disabled value={data.user_detail.name} />
              </div>
            </div>

            <span className="form__form-group-label">File Pengumpulan Tugas</span>
            <div className="form__form-group">
              {/* <div className="form__form-group-field">
                <Field
                  name="file"
                  disabled
                  component={renderFileInputField}
                />
              </div> */}
              <p>Link Pengumpulan Tugas: {data !== null ? (
                <a href={data.file} target="_blank" rel="noreferrer">
                  {data.file}
                </a>
              ) : '-'}
              </p>
            </div>

            <span className="form__form-group-label">Nilai Tugas</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="score"
                  component="input"
                  type="number"
                  min="1"
                  max="100"
                  placeholder="Nilai (ex: 1 s.d. 100)"
                />
              </div>
            </div>

            {loading && (
              <Card>
                <Col md={12} lg={12}>
                  <Progress animated value={progress} />
                </Col>
              </Card>
            )}
            <ButtonToolbar className="form__button-toolbar">
              <Button
                color="primary"
                type="submit"
              >
                Simpan
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

// EditSubmissionForm.propTypes = {
//   handleSubmit: PropTypes.func.isRequired,
// };

export default reduxForm({
  form: 'edit_submission', // a unique identifier for this form
  enableReinitialize: true,
})(EditSubmissionForm);
