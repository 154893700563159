/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Row, Progress, Modal, ModalHeader, ModalBody, ModalFooter, Button,
} from 'reactstrap';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import Select from 'react-select';
import { RiErrorWarningLine } from 'react-icons/ri';
import { LOCALSTORAGE_TOKEN } from '../../../../../../utils/Types';
import renderCheckBoxField from '../../../../../../shared/components/form/CheckBox';
import renderFileInputField from '../../../../../../shared/components/form/FileInput';
import {
  URL_CATEGORYCOURSE,
  URL_COURSE,
  URL_SUBCATEGORYCOURSEBYCATEGORY,
} from '../../../../../../utils/EndPoints';

const EditCourseForm = (stateOri) => {
  const history = useHistory();
  const params = useParams();

  const editorRef = useRef(null);
  const [isEditable, setEditable] = useState(false);
  const [progress, setProgress] = useState();
  const [course, setCourse] = useState(null);

  // list select option
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  // tentukan init category & subcategory
  const [initCategory, setInitCategory] = useState(null);
  const [initSubcategory, setInitSubcategory] = useState(null);

  // alert
  const [alertCategoriesImg, setAlertCategoriesImage] = useState(false);
  const [alertCoursesImg, setAlertCoursesImg] = useState(false);
  const [alertCoursesSmallImg, setAlertCoursesSmallImg] = useState(false);
  const [alertCoursesVideo, setAlertCoursesVideo] = useState(false);

  // loading
  const [loading, setLoading] = useState(false);

  const { location } = history;
  const { state } = location;
  const { data } = state;
  const { initialize } = stateOri;
  const [confirm, setConfirm] = useState(0);
  const [modal, setModal] = useState(false);
  const toggle = (id) => {
    setConfirm(id);
    setModal(!modal);
  };
  // const [isPublishChecked, setIsPublishChecked] = useState(false);
  // Tinymce Editor
  const [content, setContents] = useState('');
  const handleEditorChange = (contents) => {
    setContents(contents);
  };

  useEffect(() => {
    initialize(data);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(`${URL_COURSE + params.id}/`, options)
      .then((res) => {
        setCourse(res.data);
        setInitCategory(res.data.category);
        setInitSubcategory(res.data.subcategory);

        // list subcategory untuk pertama kali load
        if (res.data.category_detail) {
          axios
            .get(
              `${URL_SUBCATEGORYCOURSEBYCATEGORY + res.data.category_detail.id}/`,
              options,
            )
            .then((res1) => {
              setSubcategories(
                res1.data.map((category) => ({
                  value: category.id,
                  label: category.name,
                })),
              );
            })
            .catch((err) => {
              console.log(err);
            });
        }
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
    axios
      .get(URL_CATEGORYCOURSE, options)
      .then((res) => {
        setCategories(
          res.data.map((category) => ({
            value: category.id,
            label: category.name,
          })),
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // index selected category
  const indexCategory = categories.map((object) => object.value).indexOf(initCategory);

  // index selected subcategory
  const indexSubcategory = subcategories.map((object) => object.value).indexOf(initSubcategory);

  return (
    <Col md={12} lg={12}>
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <div className="card__title">
            <h5 className="bold-text">Ubah Kelas</h5>
          </div>
          <form
            className="form"
            onSubmit={(e) => {
              e.preventDefault();
              setLoading(true);
              const data = new FormData();
              data.append('title', e.target.title.value);
              data.append('duration', e.target.duration.value);
              data.append('lesson_hours', e.target.lesson_hours.value);
              if (e.target.categories_img.files[0]) {
                data.append('categories_img', e.target.categories_img.files[0]);
              }
              if (e.target.courses_img.files[0]) {
                data.append('courses_img', e.target.courses_img.files[0]);
              }
              if (e.target.courses_small_img.files[0]) {
                data.append(
                  'courses_small_img',
                  e.target.courses_small_img.files[0],
                );
              }
              if (e.target.course_preview_video.files[0]) {
                data.append(
                  'course_preview_video',
                  e.target.course_preview_video.files[0],
                );
              }
              if (e.target.category.value) {
                data.append('category', e.target.category.value);
              }
              if (e.target.subcategory.value) {
                data.append('subcategory', e.target.subcategory.value);
              }
              data.append('overview', e.target.content.value);
              data.append('isPublish', e.target.isPublish.checked);
              const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
              const options = {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${token}`,
                },
                onUploadProgress: (data) => {
                  setProgress(Math.round((100 * data.loaded) / data.total));
                },
              };
              axios
                .patch(`${URL_COURSE + params.id}`, data, options)
                .then(() => {
                  history.push('/admin/course');
                  setLoading(false);
                })
                .catch((err) => {
                  console.log(err);
                  setLoading(false);
                });
            }}
          >
            <input
              type="hidden"
              name="content"
              value={content}
              // defaultValue={content}
            />
            <span className="form__form-group-label">Judul</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="title"
                  disabled={!isEditable}
                  component="input"
                  type="text"
                />
              </div>
            </div>
            <span className="form__form-group-label">Kategori Kelas</span>
            <div className="form__form-group">
              {/* jika index category ada */}
              {indexCategory > -1 && (
                <Select
                  name="category"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary: '#ff4861',
                      primary25: 'hsla(352, 100%, 64%, 0.25)',
                      primary50: 'hsla(352, 100%, 64%, 0.50)',
                    },
                  })}
                  options={categories}
                  defaultValue={categories[indexCategory]}
                  onChange={(e) => {
                    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                    const options = {
                      headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${token}`,
                      },
                    };
                    axios
                      .get(
                        `${URL_SUBCATEGORYCOURSEBYCATEGORY + e.value}/`,
                        options,
                      )
                      .then((res) => {
                        setSubcategories(
                          res.data.map((category) => ({
                            value: category.id,
                            label: category.name,
                          })),
                        );
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }}
                  disabled={!isEditable}
                />
              )}

              {/* jika index subcategory tidak ada */}
              {indexCategory === -1 && (
                <Select
                  name="category"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary: '#ff4861',
                      primary25: 'hsla(352, 100%, 64%, 0.25)',
                      primary50: 'hsla(352, 100%, 64%, 0.50)',
                    },
                  })}
                  options={categories}
                  onChange={(e) => {
                    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                    const options = {
                      headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${token}`,
                      },
                    };
                    axios
                      .get(
                        `${URL_SUBCATEGORYCOURSEBYCATEGORY + e.value}/`,
                        options,
                      )
                      .then((res) => {
                        setSubcategories(
                          res.data.map((category) => ({
                            value: category.id,
                            label: category.name,
                          })),
                        );
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }}
                  disabled={!isEditable}
                />
              )}
            </div>

            <span className="form__form-group-label">Sub Kategori Kelas</span>
            <div className="form__form-group">
              {/* jika index category ada */}
              {indexSubcategory > -1 && (
                <Select
                  name="subcategory"
                  options={subcategories}
                  defaultValue={subcategories[indexSubcategory]}
                  disabled={!isEditable}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary: '#ff4861',
                      primary25: 'hsla(352, 100%, 64%, 0.25)',
                      primary50: 'hsla(352, 100%, 64%, 0.50)',
                    },
                  })}
                />
              )}

              {/* jika index subcategory tidak ada */}
              {indexCategory === -1 && (
                <Select
                  name="subcategory"
                  options={subcategories}
                  disabled={!isEditable}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary: '#ff4861',
                      primary25: 'hsla(352, 100%, 64%, 0.25)',
                      primary50: 'hsla(352, 100%, 64%, 0.50)',
                    },
                  })}
                />
              )}
            </div>

            <span className="form__form-group-label">Kategori Thumbnail</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="categories_img"
                  component={renderFileInputField}
                  accept="image/x-png,image/jpeg"
                  onChange={(e) => {
                    if (!e.file.type.includes('image/png' || 'image/jpg' || 'image/jpeg')) {
                      setAlertCategoriesImage(true);
                    } else {
                      setAlertCategoriesImage(false);
                    }
                  }}
                />
              </div>
              <p>Current Link: {course !== null ? (
                <a href={course.categories_img} target="_blank" rel="noreferrer">
                  {course.categories_img}
                </a>
              ) : '-'}
              </p>
              {alertCategoriesImg && (
                <span style={{ color: 'red' }}><RiErrorWarningLine /> Mohon isi dengan type file image</span>
              )}
            </div>

            <span className="form__form-group-label">Gambar Kelas</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="courses_img"
                  component={renderFileInputField}
                  onChange={(e) => {
                    if (!e.file.type.includes('image/png' || 'image/jpg' || 'image/jpeg')) {
                      setAlertCoursesImg(true);
                    } else {
                      setAlertCoursesImg(false);
                    }
                  }}
                />
              </div>
              <p>Current Link: {course !== null ? (
                <a href={course.courses_img} target="_blank" rel="noreferrer">
                  {course.courses_img}
                </a>
              ) : '-'}
              </p>
              {alertCoursesImg && (
              <span style={{ color: 'red' }}><RiErrorWarningLine /> Mohon isi dengan type file image</span>
              )}
            </div>

            <span className="form__form-group-label">Gambar Kecil Kelas</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="courses_small_img"
                  component={renderFileInputField}
                  onChange={(e) => {
                    if (!e.file.type.includes('image/png' || 'image/jpg' || 'image/jpeg')) {
                      setAlertCoursesSmallImg(true);
                    } else {
                      setAlertCoursesSmallImg(false);
                    }
                  }}
                />
              </div>
              <p>Current Link: {course !== null ? (
                <a href={course.courses_small_img} target="_blank" rel="noreferrer">
                  {course.courses_small_img}
                </a>
              ) : '-'}
              </p>
              {alertCoursesSmallImg && (
              <span style={{ color: 'red' }}><RiErrorWarningLine /> Mohon isi dengan type file image</span>
              )}
            </div>

            <span className="form__form-group-label">Video Pratinjau</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="course_preview_video"
                  component={renderFileInputField}
                  onChange={(e) => {
                    if (!e.file.type.includes('video')) {
                      setAlertCoursesVideo(true);
                    } else {
                      setAlertCoursesVideo(false);
                    }
                  }}
                />
              </div>
              <p>Current Link: {course !== null ? (
                <a href={course.course_preview_video} target="_blank" rel="noreferrer">
                  {course.course_preview_video}
                </a>
              ) : '-'}
              </p>
              {alertCoursesVideo && (
              <span style={{ color: 'red' }}><RiErrorWarningLine /> Mohon isi dengan type file video</span>
              )}
            </div>

            <span className="form__form-group-label">Durasi Menit</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="duration"
                  component="input"
                  type="text"
                  placeholder="Durasi Menit (ex: 120)"
                  disabled={!isEditable}
                />
              </div>
            </div>

            <span className="form__form-group-label">Jam Pelajaran (JP)</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="lesson_hours"
                  component="input"
                  type="text"
                  placeholder="Jam Pelajaran (ex: 10)"
                  disabled={!isEditable}
                />
              </div>
            </div>

            <span className="form__form-group-label">Deskripsi Kelas</span>
            <div className="form__form-group">
              <div className="form__form-group-field w-100-role-application">
                <input
                  id="my-file"
                  type="file"
                  name="my-file"
                  style={{ display: 'none' }}
                />
                <Editor
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  apiKey="koceoiwytlyiwg8ifoagfwdyi3wvjg5fk88o3gakacslqc8y"
                  initialValue={data.overview}
                  init={{
                    skin: 'snow',
                    icons: 'thin',

                    height: 500,
                    menubar: true,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen textcolor ',
                      'insertdatetime media table paste code help wordcount',
                      'image code',
                    ],
                    image_title: true,
                    automatic_uploads: true,
                    file_picker_types: 'image',
                    textcolor_rows: '4',
                    file_picker_callback(callback, value, meta) {
                      if (meta.filetype === 'image') {
                        const input = document.getElementById('my-file');
                        input.click();
                        input.onchange = function () {
                          const file = input.files[0];
                          const reader = new FileReader();
                          reader.onload = function (e) {
                            callback(e.target.result, {
                              alt: file.name,
                            });
                          };
                          reader.readAsDataURL(file);
                        };
                      }
                    },

                    content_style:
                      "@import url('http://fonts.gstatic.com/s/nunito/v7/1TiHc9yag0wq3lDO9cw0vpBw1xU1rKptJj_0jans920.woff2');",
                    font_formats: 'Nunito',

                    toolbar:
                      'undo redo | styleselect | fontselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ',
                  }}
                  onEditorChange={handleEditorChange}
                  outputFormat="html"
                />
              </div>
            </div>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="isPublish"
                  component={renderCheckBoxField}
                  disabled={!isEditable}
                  label="Apakah kelas ini ingin ditampilkan di homepage ?"
                />
              </div>
            </div>
            {loading && (
              <Card>
                <Col md={12} lg={12}>
                  <Progress animated value={progress}>{progress}%</Progress>
                </Col>
              </Card>
            )}
            <ButtonToolbar className="form__button-toolbar">
              <Row className="mt-4">
                <Col md={12}>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setEditable(!isEditable)}
                    color={isEditable ? 'danger' : 'primary'}
                  >
                    {' '}
                    {!isEditable ? 'Ubah' : 'Batal'}
                  </button>
                  {isEditable && (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={alertCoursesVideo && alertCoursesSmallImg && alertCategoriesImg && alertCoursesImg}
                    >
                      {' '}
                      Simpan Perubahan
                    </button>
                  )}
                  {isEditable === false && (
                    <button
                      type="button"
                      size="sm"
                      onClick={() => toggle(course.id)}
                      className="btn btn-danger"
                    >
                      Hapus
                    </button>
                  )}
                  <Modal
                    toggle={toggle}
                    isOpen={modal}
                    backdrop={false}
                  >
                    <ModalHeader toggle={toggle}>
                      Hapus Kelas
                    </ModalHeader>
                    <ModalBody>
                      Apakah kamu yakin ingin menghapus kelas ini?
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        onClick={() => {
                          const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                          const options = {
                            headers: {
                              'Content-Type':
                                'application/json',
                              Authorization: `Token ${token}`,
                            },
                          };
                          axios
                            .delete(
                              `${URL_COURSE}${confirm}`,
                              options,
                            )
                            .then(() => {
                              history.push('/admin/course/');
                            })
                            .catch(() => {
                              console.log('error');
                            });
                        }}
                      >
                        Hapus
                      </Button>
                    </ModalFooter>
                  </Modal>
                </Col>
              </Row>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

// EditCourseForm.propTypes = {
//   handleSubmit: PropTypes.func.isRequired,
// };

export default reduxForm({
  form: 'edit_course', // a unique identifier for this form
  enableReinitialize: true,
})(EditCourseForm);
