/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Progress, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import axios from 'axios';
import { RiErrorWarningLine } from 'react-icons/ri';
import { useHistory, useParams } from 'react-router-dom';
import {
  LOCALSTORAGE_TOKEN,
} from '../../../../../utils/Types';
import renderFileInputField from '../../../../../shared/components/form/FileInput';
import {
  URL_TESTIMONIAL,
} from '../../../../../utils/EndPoints';

const TestimonialEditForm = (stateOri) => {
  const history = useHistory();
  const params = useParams();
  const [progress, setProgress] = useState(null);
  const [isEditable, setEditable] = useState(false);
  const [file, setFile] = useState(null);
  const { location } = history;
  const { state } = location;
  const { initialize } = stateOri;
  const { data } = state;
  const [alertPhoto, setAlertPhoto] = useState(false);

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  useEffect(() => {
    initialize(data);
  }, []);

  const onSubmit = (e) => {
    // setLoading(true);
    e.preventDefault();
    const data = new FormData();
    data.append('name', e.target.name.value);
    data.append('position', e.target.position.value);
    data.append('description', e.target.description.value);
    if (e.target.photo.files[0]) {
      data.append('photo', e.target.photo.files[0]);
    }
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: (data) => {
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    };
    axios
      .patch(`${URL_TESTIMONIAL + params.id}/`, data, options)
      .then(() => {
        history.push('/admin/testimonial');
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  };

  const imageHandler = () => {
    if (file) {
      return file;
    } if (data.photo) {
      return data.photo;
    }
    return '/img/no_image.png';
  };

  const inputImageHandler = (e) => {
    setFile(URL.createObjectURL(e.file));
    const conditions = ['image/png', 'image/jpg', 'image/jpeg'];

    if (conditions.some((el) => e.file.type.includes(el))) {
      setAlertPhoto(false);
    } else {
      setAlertPhoto(true);
    }
  };

  return (
    <Col md={12} lg={12}>
      <ButtonToolbar className="form__button-toolbar">
        <Button color="danger" onClick={history.goBack}>
          Kembali
        </Button>
      </ButtonToolbar>
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <div className="card__title">
            <h5 className="bold-text">Detail Testimoni</h5>
          </div>
          <form className="form" onSubmit={onSubmit}>
            <img src={imageHandler()} alt="" className="testimonial-image" />

            <span className="form__form-group-label">Foto</span>
            <div className="form__form-group">
              <div className="form__form-group-field mb-0-label">
                <Field
                  name="photo"
                  component={renderFileInputField}
                  required
                  onChange={(e) => { inputImageHandler(e); }}
                />
              </div>
              {alertPhoto && (
                <span className="text-red"><RiErrorWarningLine /> Mohon isi dengan type image (.png, .jpeg, .jpg)</span>
              )}
            </div>

            <span className="form__form-group-label">Nama</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="name"
                  component="input"
                  type="text"
                  placeholder="Nama"
                  required
                  disabled={!isEditable}
                />
              </div>
            </div>

            <span className="form__form-group-label">Posisi</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="position"
                  component="input"
                  type="text"
                  placeholder="Posisi"
                  required
                  disabled={!isEditable}
                />
              </div>
            </div>

            <span className="form__form-group-label">Deskripsi</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="description"
                  component="textarea"
                  type="text"
                  placeholder="Deskripsi"
                  required
                  disabled={!isEditable}
                />
              </div>
            </div>

            {progress !== null && (
              <Card>
                <Col md={12} lg={12}>
                  <Progress animated value={progress}>{progress}%</Progress>
                </Col>
              </Card>
            )}

            <ButtonToolbar className="form__button-toolbar">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setEditable(!isEditable)}
                color={isEditable ? 'danger' : 'primary'}
              >
                {' '}
                {!isEditable ? 'Ubah' : 'Batal'}
              </button>
              {isEditable && (
              <button
                type="submit"
                className="btn btn-primary"
              >
                {' '}
                Simpan Perubahan
              </button>
              )}
              {isEditable === false && (
              <button
                type="button"
                size="sm"
                onClick={() => toggle()}
                className="btn btn-danger"
              >
                Hapus
              </button>
              )}
              <Modal
                toggle={toggle}
                isOpen={modal}
                backdrop={false}
              >
                <ModalHeader toggle={toggle}>
                  Hapus Testimoni
                </ModalHeader>
                <ModalBody>
                  Apakah kamu yakin ingin menghapus testimoni ini?
                </ModalBody>
                <ModalFooter>
                  <Button
                    onClick={() => {
                      const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
                      const options = {
                        headers: {
                          'Content-Type':
                                'application/json',
                          Authorization: `Token ${token}`,
                        },
                      };
                      axios
                        .delete(
                          `${URL_TESTIMONIAL}${data.id}`,
                          options,
                        )
                        .then(() => {
                          history.push('/admin/testimonial/');
                        })
                        .catch(() => {
                          console.log('error');
                        });
                    }}
                  >
                    Hapus
                  </Button>
                </ModalFooter>
              </Modal>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

// CreateCourseForm.propTypes = {
//   handleSubmit: PropTypes.func.isRequired,
// };

export default reduxForm({
  form: 'CreateELibrary_form', // a unique identifier for this form
})(TestimonialEditForm);
