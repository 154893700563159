/* eslint-disable no-console */
/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Spinner, Progress,
} from 'reactstrap';
// import { Link } from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Collapse from '../../../../../shared/components/Collapse';
import ReactTableBase from '../../../../../shared/components/table/ReactTableBase';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/Types';
import { URL_QUIZ } from '../../../../../utils/EndPoints';
import CreateQuizForm from './createQuizForm';

const QuizListTable = ({ listDataTable, isLoading }) => {
  const tableConfig = {
    isEditable: false,
    isSortable: true,
    isResizable: false,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
  };
  const [progress, setProgress] = useState();
  const { id } = useParams();

  // loading
  const [loading, setLoading] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('name', e.target.name.value);
    data.append('isFinalQuiz', e.target.isFinalQuiz.checked);
    data.append('topicId', id);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: () => {
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    };

    setLoading(true);
    axios.post(URL_QUIZ, data, options)
      .then(() => {
        window.location.reload();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="dashboard__booking-card">
          <Collapse title="Buat Kuis" className="with-shadow">
            <CreateQuizForm
              onSubmit={(e) => onSubmit(e)}
            />
          </Collapse>
          {loading && (
            <Col md={12} lg={12}>
              <Progress animated value={progress} />
            </Col>
          )}
          <div className="card__title">
            <h5 className="bold-text">List Kuis</h5>
          </div>
          {isLoading ? <Spinner /> : (
            <ReactTableBase
              columns={listDataTable.tableHeaderData}
              data={listDataTable.tableRowsData}
              tableConfig={tableConfig}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

QuizListTable.propTypes = {
  listDataTable: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default QuizListTable;
