/* eslint-disable no-shadow */
/* eslint-disable no-console */
import React from 'react';
import {
  Container,
} from 'reactstrap';
import ELibraryForm from './components/ELibraryEditForm';

const EditELibrary = () => (
  <Container className="dashboard">
    <ELibraryForm />
  </Container>
);
export default EditELibrary;
