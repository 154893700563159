/* eslint-disable no-console */
import React from 'react';
import {
  Button,
  ButtonToolbar,
  Card, CardBody, Col,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import EditTopicForm from './components/editTopicForm';

const EditTopic = () => {
  const history = useHistory();
  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <ButtonToolbar className="form__button-toolbar">
          <Button color="danger" onClick={history.goBack}>
            Kembali
          </Button>
        </ButtonToolbar>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Edit Topik {history.location.state.data.title}</h5>
            {/* <h5 className="subhead">Use default tabs with classes
            <span className="red-text"> tabs--justify tabs--bordered-bottom</span>
          </h5> */}
          </div>
          <div className="tabs tabs--justify tabs--bordered-bottom">
            <EditTopicForm />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default EditTopic;
