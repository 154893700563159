/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/react-in-jsx-scope */
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
// import { Button } from 'reactstrap';
// import { useHistory } from 'react-router-dom';

// const PhotoFormatter = (value) => (
//   <div className="products-list__img-wrap">
//     <img src={value} alt="" />
//   </div>
// );
// const topicEdit = (history, info) => {
//   history.push({
//     pathname: `/admin/topic-edit/${info.id}`,
//     state: { data: info },
//   });
// };

const SubmissionDetail = (history, info) => {
  history.push({
    pathname: `/admin/submission-detail/${info.id}`,
    state: { data: info },
  });
};

const ListDataSubmission = (dataAPI) => {
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: 'No',
        accessor: 'number',
        width: 20,
        disableSortBy: true,
      },
      {
        Header: 'Nama',
        accessor: 'name',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Submission File',
        accessor: 'file',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'score',
        accessor: 'score',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
    ], [],
  );

  const data = [];
  const rows = () => {
    if (!dataAPI) {
      data.push({
        number: '-',
      });
    } else {
      for (let i = 0; i < dataAPI.length; i += 1) {
        if (dataAPI.length === 0) {
          data.push({
            number: '-',
          });
        } else {
          data.push({
            id: dataAPI[i].id,
            number: `${i + 1}`,
            name: `${dataAPI[i].user_detail.name}`,
            score: dataAPI[i].score ? `${dataAPI[i].score}` : '-',
            file: `${dataAPI[i].file}` ? <a href={`${dataAPI[i].file}`} target="_blank" rel="noreferrer"> Lihat File </a> : '-',
            status: [
              // eslint-disable-next-line no-loop-func
              // eslint-disable-next-line max-len
              (<Button color="outline-success" onClick={() => SubmissionDetail(history, dataAPI[i])}>Detail</Button>),
            ],
          });
        }
      }
    }
  };

  rows();
  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default ListDataSubmission;
