/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Progress,
} from 'reactstrap';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { RiErrorWarningLine } from 'react-icons/ri';
import Select from 'react-select';
import {
  LOCALSTORAGE_TOKEN,
} from '../../../../../utils/Types';
import renderFileInputField from '../../../../../shared/components/form/FileInput';
import {
  URL_LIBRARY,
} from '../../../../../utils/EndPoints';

const ELibraryForm = () => {
  const history = useHistory();
  const [progress, setProgress] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [alertThumbnail, setAlertThumbnail] = useState(false);
  const [alertFilePDF, setAlertFilePDF] = useState(false);

  const onSubmit = (e) => {
    // setLoading(true);
    e.preventDefault();
    const data = new FormData();
    if (e.target.thumbnail.files[0]) {
      data.append('thumbnail', e.target.thumbnail.files[0]);
    }
    data.append('title', e.target.title.value);
    data.append('category', e.target.category.value);
    data.append('publish_year', e.target.publish_year.value);
    data.append('author', e.target.author.value);
    if (e.target.video_link) {
      data.append('video_link', e.target.video_link.value);
    }
    if (e.target.file_pdf.files[0]) {
      data.append('file_pdf', e.target.file_pdf.files[0]);
    }
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: (data) => {
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    };
    axios
      .post(URL_LIBRARY, data, options)
      .then(() => {
        history.push('/admin/e-library');
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  };

  const imageHandler = () => {
    if (thumbnail) {
      return thumbnail;
    }
    return '/img/no_image.png';
  };

  const inputImageHandler = (e) => {
    setThumbnail(URL.createObjectURL(e.file));
    const conditions = ['image/png', 'image/jpg', 'image/jpeg'];

    if (conditions.some((el) => e.file.type.includes(el))) {
      setAlertThumbnail(false);
    } else {
      setAlertThumbnail(true);
    }
  };

  return (
    <Col md={12} lg={12}>
      <ButtonToolbar className="form__button-toolbar">
        <Button color="danger" onClick={history.goBack}>
          Kembali
        </Button>
      </ButtonToolbar>
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <div className="card__title">
            <h5 className="bold-text">Buat E-Library Baru</h5>
          </div>
          <form className="form" onSubmit={onSubmit}>
            <img src={imageHandler()} alt="" className="testimonial-image" />
            <span className="form__form-group-label">Thmbnail (Gambar) dengan ukuran lebar 300 X panjang 400</span>
            <div className="form__form-group">
              <div className="form__form-group-field mb-0-label">
                <Field
                  name="thumbnail"
                  component={renderFileInputField}
                  required
                  onChange={(e) => { inputImageHandler(e); }}
                />
              </div>
              {alertThumbnail && (
                <span style={{ color: 'red' }}><RiErrorWarningLine /> Mohon isi dengan type image (.png, .jpeg, .jpg)</span>
              )}
            </div>
            <span className="form__form-group-label">Judul</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="title"
                  component="input"
                  type="text"
                  placeholder="Judul"
                  required
                />
              </div>
            </div>

            <span className="form__form-group-label">Kategori</span>
            <div className="form__form-group">
              <div className="form__form-group-field" style={{ display: 'block' }}>
                <Select
                  name="category"
                  options={[
                    { value: 'E-Book', label: 'E-Book' },
                    { value: 'Video', label: 'Video' },
                    { value: 'Makalah', label: 'Makalah' },
                    { value: 'Karya Tulis', label: 'Karya Tulis' },
                  ]}
                  required
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary: '#ff4861',
                      primary25: 'hsla(352, 100%, 64%, 0.25)',
                      primary50: 'hsla(352, 100%, 64%, 0.50)',
                    },
                  })}
                />
              </div>
            </div>

            <span className="form__form-group-label">Tahun Terbit</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="publish_year"
                  component="input"
                  type="number"
                  placeholder="Tahun Terbit"
                  required
                />
              </div>
            </div>

            <span className="form__form-group-label">Pengarang</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="author"
                  component="input"
                  type="text"
                  placeholder="Pengarang"
                  required
                />
              </div>
            </div>
            <span className="form__form-group-label">Link Video</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="video_link"
                  component="input"
                  type="url"
                  placeholder="Link Video"
                />
              </div>
            </div>
            <span className="form__form-group-label">File PDF</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="file_pdf"
                  component={renderFileInputField}
                  accept="application/pdf"
                  onChange={(e) => (e.file.type.includes('application/pdf') ? setAlertFilePDF(false) : setAlertFilePDF(true))}
                />
              </div>
              {alertFilePDF && (
                <span style={{ color: 'red' }}><RiErrorWarningLine /> Mohon isi dengan type file pdf</span>
              )}
            </div>
            {progress !== null && (
              <Card>
                <Col md={12} lg={12}>
                  <Progress animated value={progress}>{progress}%</Progress>
                </Col>
              </Card>
            )}

            <ButtonToolbar className="form__button-toolbar">
              <Button
                color="primary"
                type="submit"
              // disabled={!selectedStudent}
              >
                Submit
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

// CreateCourseForm.propTypes = {
//   handleSubmit: PropTypes.func.isRequired,
// };

export default reduxForm({
  form: 'CreateELibrary_form', // a unique identifier for this form
})(ELibraryForm);
