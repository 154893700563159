/* eslint-disable max-len */
/* eslint-disable no-console */
import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import FacebookIcon from 'mdi-react/FacebookIcon';
// import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import axios from 'axios';
import { Alert, Spinner } from 'reactstrap';
import ResetPasswordForm from './components/resetPasswordForm';
import { URL_RESET_PASSWORD } from '../../../utils/EndPoints';

const ResetPassword = () => {
  const [isLoading, setLoading] = useState(false);
  const [isAlertOn, setIsAlertOn] = useState(false);
  const [msg, setMsg] = useState('');

  const onSubmit = (e) => {
    const data = new FormData();
    data.append('email', e.email);
    setLoading(true);
    axios.post(`${URL_RESET_PASSWORD}`, data)
      .then((res) => {
        setMsg(res.data.status);
        setLoading(false);
        setIsAlertOn(true);
        setTimeout(() => { setIsAlertOn(false); }, 10000);
      })
      .catch((err) => {
        setMsg(err.response);
        setLoading(false);
        setIsAlertOn(true);
        setTimeout(() => { setIsAlertOn(false); }, 10000);
      });
  };
  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Lupa Password</h1>
          <p>Masukan email anda daftarkan di sistem SmartASN, Kami akan membantu Anda untuk melakukan pergantian password.</p>
          <div className="login-form" style={{ marginBottom: '20px' }}>
            {isAlertOn ? (
              <Alert onClick={() => setIsAlertOn(false)} color={msg === 'OK' ? 'warning' : 'danger'}>
                {msg === 'OK'
                  ? 'Link reset password telah dikirimkan ke email Anda, silahkan cek kotak masuk atau SPAM'
                  : 'Email tidak ditemukan'}
              </Alert>
            ) : ''}
          </div>
          {isLoading && <Spinner />}
          <ResetPasswordForm onSubmit={(e) => onSubmit(e)} />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
