/* eslint-disable no-console */
import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import Chart from './components/chart';

const StudentStats = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Statistik Peserta</h3>
      </Col>
    </Row>
    <Row>
      <Chart />
    </Row>
  </Container>
);

export default StudentStats;
