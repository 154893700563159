/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/react-in-jsx-scope */
import
React,
{ useMemo } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

// const PhotoFormatter = (value) => (
//   <div className="products-list__img-wrap">
//     <img src={value} alt="" />
//   </div>
// );
const liveSessionEdit = (history, info) => {
  history.push({
    pathname: `/admin/livesession-edit/${info.id}`,
    state: { data: info },
  });
};

// const materialDetail = (history, info) => {
//   history.push({
//     pathname: `/admin/course-material/${info.id}`,
//     state: { data: info },
//   });
// };

const ListDataLiveSession = (dataAPI) => {
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: 'No',
        accessor: 'number',
        width: 20,
        disableSortBy: true,
      },
      {
        Header: 'Judul Live Session',
        accessor: 'title',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Tanggal',
        accessor: 'date',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Waktu',
        accessor: 'time',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Link Meeting',
        accessor: 'link_meeting',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Password',
        accessor: 'passcode_meeting',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Aksi',
        accessor: 'action',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
    ], [],
  );

  const data = [];
  const rows = () => {
    if (!dataAPI) {
      data.push({
        number: '-',
      });
    } else {
      for (let i = 0; i < dataAPI.length; i += 1) {
        if (dataAPI.length === 0) {
          data.push({
            number: '-',
          });
        } else {
          data.push({
            id: dataAPI[i].id,
            number: `${i + 1}`,
            title: dataAPI[i].title,
            date: dataAPI[i].date === null ? '-' : dataAPI[i].date,
            time: dataAPI[i].time === null ? '-' : dataAPI[i].time,
            link_meeting: dataAPI[i].link_meeting,
            passcode_meeting: dataAPI[i].passcode_meeting,
            action: [
              // eslint-disable-next-line no-loop-func
              // eslint-disable-next-line max-len
              (<Button color="outline-success" onClick={() => liveSessionEdit(history, dataAPI[i])}>Detail</Button>),
              // (<Button color="success" onClick={() => materialDetail(history, dataAPI[i])}>Material</Button>),
            ],
          });
        }
      }
    }
  };

  rows();
  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default ListDataLiveSession;
