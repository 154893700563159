/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Button,
} from 'reactstrap';
import renderCheckBoxField from '../../../../../shared/components/form/CheckBox';

const CreateQuizForm = ({ onSubmit }) => (
  <Col md={12} lg={12}>
    <Card className="card--not-full-height">
      <CardBody className="dashboard__booking-card">
        <form className="form" onSubmit={onSubmit}>
          <span className="form__form-group-label">Judul Kuis</span>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <Field
                name="name"
                component="input"
                type="text"
                placeholder="Judul Kuis"
                required
              />
            </div>
          </div>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <Field
                name="isFinalQuiz"
                component={renderCheckBoxField}
                label="Apakah Kuis Final?"
              />
            </div>
          </div>
          <ButtonToolbar className="form__button-toolbar">
            <Button
              color="primary"
              type="submit"
            // disabled={!selectedStudent}
            >
              Simpan
            </Button>
          </ButtonToolbar>
        </form>
      </CardBody>
    </Card>
  </Col>
);

CreateQuizForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'create_quiz', // a unique identifier for this form
})(CreateQuizForm);
