/* eslint-disable max-len */
/* eslint-disable no-console */
import React from 'react';
import {
  Card, CardBody, Col, Spinner,
} from 'reactstrap';
import PropTypes from 'prop-types';

const DescriptionCard = ({
  title, icon, loading, total, error,
}) => (
  <Col md={12} xl={3} lg={6} xs={12}>
    <Card>
      <CardBody className="dashboard__card-widget">
        <div className="dashboard-user-content mobile-app-widget">
          {error !== '' ? (
            <div className="user-left-content mobile-app-widget__top-line mobile-app-widget__top-line--pink">
              <div className="mobile-app-widget__total-stat">
                [{error}]
              </div>
              <h5>{title}</h5>
            </div>
          ) : (
            <div className="user-left-content mobile-app-widget__top-line mobile-app-widget__top-line--pink">
              <div className="mobile-app-widget__total-stat">
                {loading ? <span className="spinner-style"><Spinner /></span> : total}
              </div>
              <h5>{title}</h5>
            </div>
          )}
          {icon}
          {/* <Progress value={87}><p className="progress__label">87%</p></Progress> */}
        </div>
      </CardBody>
    </Card>
  </Col>
);

DescriptionCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  loading: PropTypes.bool.isRequired,
  total: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
};

export default DescriptionCard;
