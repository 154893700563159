/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Button,
} from 'reactstrap';

const CreateLiveSessionForm = ({ handleSubmit }) => (
  <Col md={12} lg={12}>
    <Card className="card--not-full-height">
      <CardBody className="dashboard__booking-card">
        <form className="form" onSubmit={handleSubmit}>
          <span className="form__form-group-label">Judul Live Session</span>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <Field
                name="title"
                component="input"
                type="text"
                placeholder="Judul live session"
                required
              />
            </div>
          </div>
          <span className="form__form-group-label">Tanggal Live Session</span>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <Field
                name="date"
                component="input"
                type="date"
                placeholder="Pilih tanggal"
              />
            </div>
          </div>
          <span className="form__form-group-label">Jam Live Session</span>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <Field
                name="time"
                component="input"
                type="time"
                placeholder="Pilih jam mulai"
              />
            </div>
          </div>
          <span className="form__form-group-label">Link Meeting Live Session</span>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <Field
                name="link_meeting"
                component="input"
                type="text"
                placeholder="Masukan Link Meeting"
                required
              />
            </div>
          </div>
          <span className="form__form-group-label">Passcode Live Session</span>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <Field
                name="passcode_meeting"
                component="input"
                type="text"
                // placeholder="Pilih tanggal"
                required
              />
            </div>
          </div>
          <ButtonToolbar className="form__button-toolbar">
            <Button
              color="primary"
              type="submit"
            // disabled={!selectedStudent}
            >
              Simpan
            </Button>
          </ButtonToolbar>
        </form>
      </CardBody>
    </Card>
  </Col>
);

CreateLiveSessionForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'create_live_session', // a unique identifier for this form
})(CreateLiveSessionForm);
