/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/react-in-jsx-scope */
import
React,
{ useMemo } from 'react';
import { Button } from 'reactstrap';
import DOMPurify from 'dompurify';
import { useHistory } from 'react-router-dom';
// const PhotoFormatter = (value) => (
//   <div className="products-list__img-wrap">
//     <img src={value} alt="" />
//   </div>
// );
const questionEdit = (history, info) => {
  history.push({
    pathname: `/admin/quiz-question-edit/${info.id}`,
    state: { data: info },
  });
};

const ListDataQuestion = (dataAPI) => {
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: 'No',
        accessor: 'number',
        width: 20,
        disableSortBy: true,
      },
      {
        Header: 'Jenis Soal',
        accessor: 'type',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Pertanyaan',
        accessor: 'question_text',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Jawaban Benar',
        accessor: 'correct_answer',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Bobot',
        accessor: 'bobot_correct',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Aksi',
        accessor: 'action',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
    ], [],
  );

  const data = [];
  const rows = () => {
    if (!dataAPI) {
      data.push({
        number: '-',
      });
    } else {
      for (let i = 0; i < dataAPI.length; i += 1) {
        if (dataAPI.length === 0) {
          data.push({
            number: '-',
          });
        } else {
          data.push({
            id: dataAPI[i].id,
            number: `${i + 1}`,
            type: dataAPI[i].type,
            // eslint-disable-next-line react/no-danger
            question_text: <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dataAPI[i].question_text) }} />,
            correct_answer: dataAPI[i].correct_answer,
            bobot_correct: `${dataAPI[i].bobot_correct}`,
            action: [
              // eslint-disable-next-line no-loop-func
              // eslint-disable-next-line max-len
              (<Button color="outline-success" onClick={() => questionEdit(history, dataAPI[i])}>Detail</Button>),
            ],
          });
        }
      }
    }
  };

  rows();
  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default ListDataQuestion;
