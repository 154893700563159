/* eslint-disable no-shadow */
/* eslint-disable no-console */
/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Spinner, Progress,
} from 'reactstrap';
import axios from 'axios';
// import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Collapse from '../../../../../shared/components/Collapse';
import ReactTableBase from '../../../../../shared/components/table/ReactTableBase';
import CreateInsturctorForm from './createInsturctorForm';
import { URL_INSTRUCTOR } from '../../../../../utils/EndPoints';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/Types';

const InstructorListTable = ({ listDataTable, isLoading }) => {
  const tableConfig = {
    isEditable: false,
    isSortable: true,
    isResizable: false,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
  };

  const { id } = useParams();
  const [progress, setProgress] = useState();

  // loading
  const [loading, setLoading] = useState(false);

  const onSubmit = (e) => {
    // setLoading(true);
    e.preventDefault();
    const data = new FormData();
    data.append('userId', e.target.teacherId.value);
    data.append('position', e.target.position.value);
    data.append('courseId', id);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      onUploadProgress: (data) => {
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    };
    setLoading(true);
    axios.post(URL_INSTRUCTOR, data, options)
      .then(() => {
        window.location.reload();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response.data);
        setLoading(false);
      });
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody className="dashboard__booking-card">
          <Collapse title="Tambahkan Guru Pengajar" className="with-shadow">
            <CreateInsturctorForm
              handleSubmit={(e) => onSubmit(e)}
            />
          </Collapse>
          {loading && (
            <Col md={12} lg={12}>
              <Progress animated value={progress}>{progress}%</Progress>
            </Col>
          )}
          <div className="card__title">
            <h5 className="bold-text">List Guru Pengajar</h5>
          </div>
          {isLoading ? <Spinner /> : (
            <ReactTableBase
              columns={listDataTable.tableHeaderData}
              data={listDataTable.tableRowsData}
              tableConfig={tableConfig}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

InstructorListTable.propTypes = {
  listDataTable: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default InstructorListTable;
