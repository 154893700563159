/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import {
  Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import classnames from 'classnames';
import MaterialListTable from './materialList';
import {
  URL_MATERIAL_BY_IDTOPIC,
  URL_QUIZ_BY_IDTOPIC,
  URL_LIVE_SESSION_BY_IDTOPIC,
  URL_ASSIGNMENTBYTOPIC,
} from '../../../../../utils/EndPoints';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/Types';
import ListDataMaterial from './ListDataMaterial';
import ListDataQuiz from '../../Quiz/components/listDataQuiz';
import QuizListTable from '../../Quiz/components/quizList';
import LiveSessionListTable from '../../LiveSession/components/liveSessionList';
import ListDataLiveSession from '../../LiveSession/components/listDataLiveSession';
import AssignmentListTable from '../../Assignment/components/AssignmentList';
import ListDataAssignment from '../../Assignment/components/listDataAssignment';

const CourseMaterialContent = () => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const history = useHistory();
  const { id } = useParams();

  // list
  const [material, setMaterial] = useState([]);
  const [quiz, setQuiz] = useState([]);
  const [session, setSession] = useState([]);
  const [assignment, setAssignment] = useState([]);

  const listDataTable = ListDataMaterial(material);
  const listDataTable1 = ListDataQuiz(quiz);
  const listDataTable2 = ListDataLiveSession(session);
  const listDataTable3 = ListDataAssignment(assignment);

  // loading
  const [loadingMaterial, setLoadingMaterial] = useState(false);
  const [loadingQuiz, setLoadingQuiz] = useState(false);
  const [loadingSession, setLoadingSession] = useState(false);
  const [loadingAssignment, setLoadingAssignment] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };

    // get material
    setLoadingMaterial(true);
    axios.get(`${URL_MATERIAL_BY_IDTOPIC}${history.location.pathname.slice(23)}/`, options)
      .then((res) => {
        setMaterial(res.data);
        setLoadingMaterial(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingMaterial(false);
      });

    // get quiz
    setLoadingQuiz(true);
    axios.get(`${URL_QUIZ_BY_IDTOPIC}${history.location.pathname.slice(23)}/`, options)
      .then((res) => {
        setQuiz(res.data);
        setLoadingQuiz(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingQuiz(false);
      });
    // get live session
    setLoadingSession(true);
    axios.get(`${URL_LIVE_SESSION_BY_IDTOPIC}${id}/`, options)
      .then((res) => {
        setSession(res.data);
        setLoadingSession(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingSession(false);
      });
    // get assignment
    setLoadingAssignment(true);
    axios.get(`${URL_ASSIGNMENTBYTOPIC}${id}/`, options)
      .then((res) => {
        setAssignment(res.data);
        setLoadingAssignment(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingAssignment(false);
      });
  }, []);

  return (
    <div className="tabs__wrap">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => toggle('1')}
          >
            Material
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => toggle('2')}
          >
            Kuis
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => toggle('3')}
          >
            Tugas
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '4' })}
            onClick={() => toggle('4')}
          >
            Live Session
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => toggle('3')}
          >
            Offers
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '4' })}
            onClick={() => toggle('4')}
          >
            Refounds
          </NavLink>
        </NavItem> */}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <MaterialListTable
            listDataTable={listDataTable}
            isLoading={loadingMaterial}
          />
        </TabPane>
        <TabPane tabId="2">
          <QuizListTable
            listDataTable={listDataTable1}
            isLoading={loadingQuiz}
          />
        </TabPane>
        <TabPane tabId="3">
          <AssignmentListTable
            listDataTable={listDataTable3}
            isLoading={loadingAssignment}
          />
        </TabPane>
        <TabPane tabId="4">
          <LiveSessionListTable
            listDataTable={listDataTable2}
            isLoading={loadingSession}
          />
        </TabPane>
        {/* <TabPane tabId="3">
          <p>Direction has strangers now believing. Respect enjoyed gay far exposed parlors towards. Enjoyment
            use tolerably dependent listening men. No peculiar in handsome together unlocked do by.
          </p>
        </TabPane>
        <TabPane tabId="4">
          <p>Direction has strangers now believing. Respect enjoyed gay far exposed parlors towards. Enjoyment
            use tolerably dependent listening men. No peculiar in handsome together unlocked do by. Article
            concern joy anxious did picture sir her.
          </p>
        </TabPane> */}
      </TabContent>
    </div>
  );
};

export default CourseMaterialContent;
