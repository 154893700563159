/* eslint-disable no-shadow */
/* eslint-disable no-console */
import React from 'react';
import {
  Container,
} from 'reactstrap';
import TestimonialEditForm from './components/TestimonialEditForm';

const EditTestimonial = () => (
  <Container className="dashboard">
    <TestimonialEditForm />
  </Container>
);
export default EditTestimonial;
