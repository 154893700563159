/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Button, Spinner,
} from 'reactstrap';
import Select from 'react-select';
import axios from 'axios';
import { URL_DATAASN } from '../../../../../utils/EndPoints';
import { LOCALSTORAGE_TOKEN } from '../../../../../utils/Types';

const CreateUserForm = ({ handleSubmit }) => {
  const [nip, setNip] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios.get(`${URL_DATAASN}`, options)
      .then((res) => {
        setNip(
          res.data.map((e) => ({
            value: e.id,
            label: `${e.nip_baru} | ${e.nama}`,
          })),
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <Col md={12} lg={12}>
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <form className="form" onSubmit={handleSubmit}>
            <span className="form__form-group-label">Nama Lengkap</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="name"
                  component="input"
                  type="text"
                  placeholder="Tulis nama lengkap"
                  required
                />
              </div>
            </div>
            <span className="form__form-group-label">Email</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="email"
                  component="input"
                  type="email"
                  placeholder="Tulis Email"
                  required
                />
              </div>
            </div>
            {loading && <Spinner />}
            <span className="form__form-group-label">NIP</span>
            <div className="form__form-group">
              <div className="form__form-group-field" style={{ display: 'block' }}>
                <Select
                  name="nip"
                  options={nip || []}
                  isClearable="false"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary: '#ff4861',
                      primary25: 'hsla(352, 100%, 64%, 0.25)',
                      primary50: 'hsla(352, 100%, 64%, 0.50)',
                    },
                  })}
                />
              </div>
            </div>
            <span className="form__form-group-label">Role</span>
            <div className="form__form-group">
              <div className="form__form-group-field" style={{ display: 'block' }}>
                <Select
                  name="role"
                  defaultValue={{ label: 'Murid', value: 'Student' }}
                  options={[
                    { label: 'Murid', value: 'Student' },
                    { label: 'Guru', value: 'Teacher' },
                  ]}
                  required
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary: '#ff4861',
                      primary25: 'hsla(352, 100%, 64%, 0.25)',
                      primary50: 'hsla(352, 100%, 64%, 0.50)',
                    },
                  })}
                />
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar">
              <Button
                color="primary"
                type="submit"
              >
                Simpan
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

CreateUserForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'teacher_create', // a unique identifier for this form
})(CreateUserForm);
