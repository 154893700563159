/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/react-in-jsx-scope */
import
React,
{ useMemo } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

const courseDetail = (history, info) => {
  history.push({
    pathname: `/admin/course-detail/${info.id}`,
    state: { data: info },
  });
};

const Topic = (history, info) => {
  history.push({
    pathname: `/admin/course-topic/${info.id}`,
    state: { data: info },
  });
};

const ListData = (dataAPI) => {
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        Header: 'No',
        accessor: 'number',
        width: 20,
        disableSortBy: true,
      },
      {
        Header: 'Judul',
        accessor: 'title',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Kategori',
        accessor: 'category',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Sub Kategori',
        accessor: 'subcategory',
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Tampilkan Di Homepage',
        accessor: 'is_publish',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableGlobalFilter: true,
        disableSortBy: true,
        width: 110,
      },
    ], [],
  );

  const data = [];
  const rows = () => {
    if (!dataAPI) {
      data.push({
        number: '-',
      });
    } else {
      for (let i = 0; i < dataAPI.length; i += 1) {
        if (dataAPI.length === 0) {
          data.push({
            number: '-',
          });
        } else {
          data.push({
            id: dataAPI[i].id,
            number: `${i + 1}`,
            title: dataAPI[i].title,
            category: `${dataAPI[i].category ? dataAPI[i].category_detail.name : '-'}`,
            subcategory: `${dataAPI[i].subcategory ? dataAPI[i].subcategory_detail.name : '-'}`,
            is_publish: <div className="course-show-in-homepage">{dataAPI[i].isPublish === false ? '✘' : '✔'}</div>,
            status: [
              // eslint-disable-next-line no-loop-func
              // eslint-disable-next-line max-len
              (<Button key={dataAPI[i].id} color="outline-success" onClick={() => courseDetail(history, dataAPI[i])}>Detail</Button>),
              (<Button key={`${i + 1}`} color="outline-success" onClick={() => Topic(history, dataAPI[i])}>Topik</Button>),
            ],
          });
        }
      }
    }
  };

  rows();
  const partnerListTableData = { tableHeaderData: columns, tableRowsData: data };
  return partnerListTableData;
};

export default ListData;
