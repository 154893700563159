/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { RiErrorWarningLine } from 'react-icons/ri';
import { Field, reduxForm } from 'redux-form';
import {
  Card, CardBody, Col, ButtonToolbar, Button,
} from 'reactstrap';
import { Editor } from '@tinymce/tinymce-react';
import renderFileInputField from '../../../../../shared/components/form/FileInput';

const CreateTeacherForm = ({ handleSubmit }) => {
  const editorRef = useRef(null);
  // Tinymce Editor
  const [content, setContents] = useState('');
  const handleEditorChange = (contents) => {
    setContents(contents);
  };

  const [alert, setAlert] = useState(false);

  return (
    <Col md={12} lg={12}>
      <Card className="card--not-full-height">
        <CardBody className="dashboard__booking-card">
          <form className="form" onSubmit={handleSubmit}>
            <input
              type="hidden"
              name="content"
              id=""
              value={content}
              // defaultValue={content}
            />

            <span className="form__form-group-label">Nama Lengkap</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="name"
                  component="input"
                  type="text"
                  placeholder="Tulis nama lengkap"
                  required
                />
              </div>
            </div>
            <span className="form__form-group-label">Email</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="email"
                  component="input"
                  type="email"
                  placeholder="Tulis Email"
                  required
                />
              </div>
            </div>
            <span className="form__form-group-label">Password</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="password"
                  component="input"
                  type="text"
                  placeholder="Tulis password"
                  required
                />
              </div>
            </div>
            <span className="form__form-group-label">Foto Guru</span>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="profilePhoto"
                  component={renderFileInputField}
                  onChange={(e) => {
                    if (!e.file.type.includes('image/png' || 'image/jpg' || 'image/jpeg')) {
                      setAlert(true);
                    } else {
                      setAlert(false);
                    }
                  }}
                  required
                />
              </div>
              {alert && (
                <span style={{ color: 'red' }}><RiErrorWarningLine /> Mohon isi dengan type file image png / jpg / jepg</span>
              )}
            </div>
            <span className="form__form-group-label">Deskripsi Kelas</span>
            <div className="form__form-group">
              <div className="form__form-group-field w-100-role-application">
                <input
                  id="my-file"
                  type="file"
                  name="my-file"
                  style={{ display: 'none' }}
                />
                <Editor
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  // apiKey="yb64t1vjyri2cansglhq8gaz1tai01e0dc0g3jlm9a4cjvdn"
                  apiKey="koceoiwytlyiwg8ifoagfwdyi3wvjg5fk88o3gakacslqc8y"
                  init={{
                    skin: 'snow',
                    icons: 'thin',
                    // placeholder:`${t('dashboard_teacher.create_course.description_message')}`,

                    height: 500,
                    menubar: true,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen textcolor ',
                      'insertdatetime media table paste code help wordcount',
                      'image code',
                    ],
                    image_title: true,
                    automatic_uploads: true,
                    file_picker_types: 'image',
                    textcolor_rows: '4',
                    file_picker_callback(callback, value, meta) {
                      if (meta.filetype === 'image') {
                        const input = document.getElementById('my-file');
                        input.click();
                        input.onchange = function () {
                          const file = input.files[0];
                          const reader = new FileReader();
                          reader.onload = function (e) {
                            callback(e.target.result, {
                              alt: file.name,
                            });
                          };
                          reader.readAsDataURL(file);
                        };
                      }
                    },

                    content_style:
                      "@import url('http://fonts.gstatic.com/s/nunito/v7/1TiHc9yag0wq3lDO9cw0vpBw1xU1rKptJj_0jans920.woff2');",
                    font_formats: 'Nunito',

                    toolbar:
                      'undo redo | styleselect | fontselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ',
                  }}
                  onEditorChange={handleEditorChange}
                  outputFormat="html"
                />
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar">
              <Button
                color="primary"
                type="submit"
              // disabled={!selectedStudent}
              >
                Simpan
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

CreateTeacherForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'teacher_create', // a unique identifier for this form
})(CreateTeacherForm);
