/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, {
  useState, useEffect,
} from 'react';
// import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonToolbar,
  Card, CardBody, Col,
} from 'reactstrap';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import CourseTopicContent from './component/courseDetail';
import { LOCALSTORAGE_TOKEN } from '../../../../utils/Types';
import { URL_COURSE } from '../../../../utils/EndPoints';

const CourseTopic = () => {
  const history = useHistory();
  const { id } = useParams();
  const [course, setCourse] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(`${URL_COURSE + id}/`, options)
      .then((res) => {
        setCourse(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <ButtonToolbar className="form__button-toolbar">
          <Button color="danger" onClick={() => history.push('/admin/course/')}>
            Kembali
          </Button>
        </ButtonToolbar>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Topik Kelas {course != null && course.title}</h5>
            {/* <h5 className="subhead">Use default tabs with classes
            <span className="red-text"> tabs--justify tabs--bordered-bottom</span>
          </h5> */}
          </div>
          <div className="tabs tabs--justify tabs--bordered-bottom">
            <CourseTopicContent />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};
export default CourseTopic;
